import React from 'react';
import { Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import InputField from "src/components/common/Inputs/InputField";
import { CustomButton } from "src/components/common/Buttons/CustomButton";

const schema = Yup.object().shape({
  email: Yup.string().email("Must be a valid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

interface ParentSignInFormProps {
  onSubmit: (values: any) => void;
  loading: boolean;
  error?: string;
  handleForgotPassword: () => void
}

export const ParentSignInForm: React.FC<ParentSignInFormProps> = ({ onSubmit, loading, error, handleForgotPassword }) => {
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <InputField
        label="Email Address"
        type="email"
        name="email"
        placeholder="Enter your email address"
        error={form.errors.email}
        {...form.getInputProps('email')}
      />
      <InputField
        label="Password"
        type="password"
        name="password"
        placeholder="Enter your password"
        showPasswordToggle
        error={form.errors.password}
        {...form.getInputProps('password')}
      />
      <div className="flex justify-between items-center my-5">
        <label className="flex items-center">
          <input type="checkbox" className="mr-2" />
          <span className="text-sm text-gray-600">Remember me</span>
        </label>
        <a onClick={handleForgotPassword} className="text-sm text-blue-600">Forgot Password?</a>
      </div>
      <Text className="text-sm text-gray-600 my-5">
        By signing in, you agree to our <a href="#" className="text-blue-600">Terms of Use</a> and <a href="#" className="text-blue-600">Privacy Policy</a>.
      </Text>
      <CustomButton type="submit" loading={loading} disabled={!form.isValid()}>Sign In</CustomButton>
      {error && <Text color="red" className="mt-2">{error}</Text>}
    </form>
  );
};