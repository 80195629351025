// // ProtectedRoute.js
// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from 'src/hooks/useAuth';
// import { authRoutes } from '../routes/auth';
// import { LoadingOverlay } from '@mantine/core';
// import { UserRole } from 'src/enums/User';


// const ProtectedRoute = ({ children }) => {
//   const { token, loading, } = useAuth();

//   if (loading) {
//     // Optionally, render a loading indicator while checking the token
//     return <LoadingOverlay visible={loading}/>;
//   }

//   window.addEventListener('logout', () => <Navigate to={authRoutes.LOGIN} replace />);

//   if (!token?.access_token) {
//     // If there's no token, redirect to the login page
//     return <Navigate to={authRoutes.LOGIN} replace />;
//   }

//   return children;
// };

// export default ProtectedRoute;


import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { authRoutes } from '../routes/auth';
import { LoadingOverlay } from '@mantine/core';
import { UserRole } from 'src/enums/User';
import { userRoutes } from '../routes/user';
import { parentRoutes } from '../routes/ParentRoutes';

interface ProtectedRouteProps {
  children: React.ReactNode;
  allowedRoles?: UserRole[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles }) => {
  const { token, loading, role, logout } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const handleLogout = () => {
      logout();
    };

    window.addEventListener('logout', handleLogout);

    return () => {
      window.removeEventListener('logout', handleLogout);
    };
  }, [logout]);

  if (loading) {
    return <LoadingOverlay visible={loading} />;
  }

  if (!token?.access_token) {
    // If there's no token, redirect to the login page
    return <Navigate to={authRoutes.LOGIN} state={{ from: location }} replace />;
  }

  // Check if the user's role is allowed for this route
  if (allowedRoles && !allowedRoles.includes(role as UserRole)) {
    // Redirect to an appropriate page based on the user's role
    if (role === UserRole.user) {
      return <Navigate to={userRoutes.DASHBOARD} replace />;
    } else if (role === UserRole.parent) {
      return <Navigate to={parentRoutes.DASHBOARD} replace />;
    } else {
      // Fallback for unknown roles
      return <Navigate to="/unauthorized" replace />;
    }
  }

  return <>{children}</>;
};

export default ProtectedRoute;