import React from 'react';
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { Text } from "@mantine/core";
import InputField from "src/components/common/Inputs/InputField";
import { CustomButton } from "src/components/common/Buttons/CustomButton";
import { AuthHeader } from 'src/components/Cards/AuthHeaderCard';

const schema = Yup.object().shape({
  email: Yup.string().email("Must be a valid email").required("Email is required"),
});

interface EnterEmailProps {
  onSubmit: (email: string) => void;
  loading: boolean;
  error?: string;
}

export const EnterEmail: React.FC<EnterEmailProps> = ({ onSubmit, loading, error }) => {
  const form = useForm({
    initialValues: { email: "" },
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values.email))}>
      <div className='space-y-6'>
      <AuthHeader title='Trouble logging in?' subtitle="Enter your email address and we'll send you a code to reset your password."/>
      <InputField
        label="Email Address"
        type="email"
        name="email"
        placeholder="Enter your email address"
        error={form.errors.email}
        {...form.getInputProps('email')}
      />
      <CustomButton type="submit" loading={loading} disabled={!form.isValid()} className="mt-4">
        Send Code
      </CustomButton>
      {error && <Text color="red" className="mt-2">{error}</Text>}
      </div>

    </form>
  );
};