import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from 'src/components/Layout/AuthLayout';
import { VisualSideLayout } from 'src/components/Layout/VisualsLayout';
import { SignUpForm } from 'src/components/Forms/Signup';
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { ErrorKind } from "src/services/services/MainService/apiProblem";
import { authRoutes } from "src/navigators/routes/auth";

const SignUp: React.FC = () => {
  const navigation = useNavigate()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigateToSignin = useCallback(() => {
  navigation("/auth/login");
}, []);

  const signUp = async (values: any) => {
    setLoading(true)
    try{
      const response = await authService.signUp(
        values.name,
        values.email,
        values.password
      );
  
      if (response.kind == ResponseKind.OK) {
        setError('')
        const data = response.data;
  
        if (data?.is_active) {
        } else {
          navigation(authRoutes.LOGIN);
          alert("Account activation link has been sent to your email")
        }
      } else if (response.kind == ErrorKind.REJECTION_ERROR) {
        setError(response?.errors);
        throw response.errors
      }
    }catch(e){
      
    }finally{
      setLoading(false)
    }
  };

  return (
    <AuthLayout
      visualComponent={<VisualSideLayout />}
      formComponent={
        <SignUpForm 
          handleSignin={navigateToSignin}
          onSubmit={signUp}
          loading={loading}
          error={error}
        />
      }
    />
  );
};

export default SignUp;





