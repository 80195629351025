import React from 'react';
import { Image, Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import InputField from "src/components/common/Inputs/InputField";
import { CustomButton } from "src/components/common/Buttons/CustomButton";
import { AuthHeader } from '../Cards/AuthHeaderCard';

const schema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Must be a valid email").required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must have at least one special character")
    .matches(/\d/, "Password must have at least one number"),
});

interface SignUpFormProps {
  onSubmit: (values: any) => void;
  handleSignin: () => void
  loading: boolean;
  error?: string;
}

export const SignUpForm: React.FC<SignUpFormProps> = ({ onSubmit, loading, error, handleSignin }) => {
    const form = useForm({
        initialValues: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          password: "",
        },
        validate: yupResolver(schema),
        validateInputOnBlur: true,
        validateInputOnChange: true,
      });

  return (
    <div className="w-full max-w-md space-y-6">
      <AuthHeader title='Sign Up' subtitle='Enter your details to create an account' />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <InputField
          label="First Name"
          name="firstName"
          placeholder="Enter your first name"
          {...form.getInputProps('firstName')}
          error={form.errors.firstName?.toString()}
        />
        <InputField
          label="Last Name"
          name="lastName"
          placeholder="Enter your last name"
          {...form.getInputProps('lastName')}
          error={form.errors.lastName?.toString()}
        />
        <InputField
          label="Email Address"
          type="email"
          name="email"
          placeholder="Enter your email address"
          {...form.getInputProps('email')}
          error={form.errors.email?.toString()}
        />
        <InputField
          label="Phone Number"
          type="tel"
          name="phone"
          placeholder="Enter your phone number"
          {...form.getInputProps('phone')}
          error={form.errors.phone?.toString()}
        />
        <InputField
          label="Password"
          type="password"
          name="password"
          placeholder="Enter your password"
          showPasswordToggle
          {...form.getInputProps('password')}
          error={form.errors.password?.toString()}
        />
        <p className="mb-5 mt-3 text-sm text-gray-600">
          By signing up, you agree to our <a href="#" className="text-blue-600">Terms of Use</a> and <a href="#" className="text-blue-600">Privacy Policy</a>.
        </p>
        <CustomButton type="submit" loading={loading} disabled={!form.isValid()}>Sign Up</CustomButton>
        <p className="mt-5 text-sm text-center text-gray-600">
          Already have an account? <a onClick={handleSignin} className="text-blue-600">Login</a>
        </p>
      </form>
      {error && <Text color="red" className="mt-2">{error}</Text>}
    </div>
  );
};