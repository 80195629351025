import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, CheckIcon } from "@heroicons/react/24/solid";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { Apple, Circle, EmailOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  ActionIcon,
  Button,
  Card,
  Center,
  Divider,
  Grid,
  Image,
  List,
  Space,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import { AppButton } from "src/components/common/Buttons/AppButton";
import Icon from "src/components/common/Icons/Icons/Icon";
import { IconButton } from "src/components/common/Buttons/IconButton";
import { FocusButton } from "src/components/common/Buttons/FocusButton";
import useResponsive from "src/hooks/useResponsive";
import { useMediaQuery } from "@mantine/hooks";
import theme from "@material-tailwind/react/theme";
import { colors } from "@mui/material";
import { userRoutes } from "src/navigators/routes/user";
import { useAppDispatch } from "src/redux/redux-store/hooks";
import { handleUserUpdate } from "src/redux/redux-store";

const PaymentFeedback: FunctionComponent = () => {
  const [verificationMessage, setVerificationMessage] = useState("");
  const url = window?.location?.href;
  const dispatch = useAppDispatch()
  const navigation = useNavigate();
  const isMobile = useMediaQuery("(max-width: 500px)");

  const navigateToHome = () => {
    navigation(userRoutes.DASHBOARD, {replace: true})
  }

  useEffect(() => {
    dispatch(handleUserUpdate())
  }, [])

  return (
    <div className="w-full h-full bg-black">
      <div className="h-10 flex flex-row space-x-2 pl-4 pt-4 items-center">
        <ActionIcon onClick={navigateToHome} ><Icon size="1rem" name="back" color="white" /></ActionIcon>
        <Text size={"sm"} style={{color: 'white'}}>Dashboard</Text>
      </div>
      <div className="h-full grid lg:grid-cols-3">
        <Card
          bg={"black"}
          style={{
            borderWidth: 1,
            borderRadius: 0,
            borderColor: colors.grey[600],
          }}
          className="flex lg:col-span-1"
        >
          <div
            className={`flex flex-1 self-center items-center justify-center`}
          >
            <Card
              style={{ borderWidth: 4, borderColor: COLORS.primary }}
              withBorder
              className={`shrink-0 ${
                isMobile ? "w-[100%]" : "w[80%]"
              } bg-black h-[50%] flex-col rounded-2xl p-10`}
            >
              {/* <Image
                radius={4}
                height={isMobile ? "6rem" : "12rem"}
                width={isMobile ? "6rem" : "12rem"}
                src={"/appIcon.jpeg"}
              /> */}
              <Space h={20} />
              <Text style={{ color: "white" }} fw={600} size={"lg"}>
                Tutorbot Premium
              </Text>
              <Text style={{ color: "white" }} size={"sm"} fw={600}>
                Enjoy the full capabilities of Tutorbot without any limits
              </Text>
              <Space h={20} />
              <FocusButton
                w={isMobile ? "100%" : "60%"}
                text="Visit Customer Portal"
                icon="copy"
              />
              <Space h={20} />
              <Text>
                <List
                  style={{ color: "white" }}
                  px={"sm"}
                  spacing="xs"
                  size="md"
                  center
                >
              <List.Item>
                  <Text style={{ color: "white" }} size={"sm"}>
                    You can now upload as many question as you like
                  </Text>
                </List.Item>
                <List.Item>
                  <Text style={{ color: "white" }} size={"sm"}>
                    You can now chat seamlessly with Tutorbot
                  </Text>
                </List.Item>
                <List.Item>
                  <Text style={{ color: "white" }} size={"sm"}>
                    You can clear your chat at any time
                  </Text>
                </List.Item>
                </List>
              </Text>
            </Card>
          </div>
        </Card>

        <Card
          style={{ borderRadius: 0 }}
          className="flex lg:col-span-2 items-center justify-center bg-black"
        >
          <div className="flex p-4 flex-col item-center place-items-center justify-center space-y-4 bg-green-100 rounded-2xl">
            <ThemeIcon radius={40} p={"md"} color="green">
              <Icon size="1.5rem" name="success" />{" "}
            </ThemeIcon>
            <Text style={{ color: colors.green[600] }} size={"sm"}>
              Your payment has been successfully made
            </Text>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PaymentFeedback;
