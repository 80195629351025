import { ActionIcon, Image, Loader, useMantineTheme } from "@mantine/core";
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from 'react-markdown';
import Icon from "../common/Icons/Icons/Icon";
import { isValidJSON } from "src/utils/helpers";

interface BotMessage {
  message: string
  index: number
  isLatest?: boolean
  streaming?: boolean
  traceless?: boolean
  toggleOptions?: () => void
  closeOptions?: () => void
  showOptions?: boolean
}

const BotMessage = (props: BotMessage) => {
  const { streaming, isLatest, traceless, toggleOptions = () => { }, closeOptions = () => { }, showOptions } = props
  const theme = useMantineTheme()
  const [latest, setLatest] = useState(isLatest)
  const lastMessageRef = useRef<HTMLDivElement | null>(null)
  const clipboard = useClipboard({ timeout: 500 });

  useEffect(() => {
    setLatest(isLatest)
  }, [isLatest])

  const handleCopy = (textToCopy: string) => {
    clipboard.copy(textToCopy);
    showNotification({
      message: 'Text copied to clipboard',
      color: "grape",
    });
  };

  if (streaming && (!props?.message || props.message.length === 0)) {
    return (<div className="inline-block text-center bg-primary rounded-tr-lg rounded-b-lg p-2.5 mb-5 mx-auto">
      <Loader size={"md"} variant="dots" color="gray" />
    </div>)
  }

  if (isValidJSON(props.message)) {
    const formattedMessage = JSON.parse(props.message)
    return (
      <div>
        <div className="rounded flex flex-col items-start justify-start pt-4 px-0 pb-0 box-border max-w-[736px] mq800:max-w-full">
          <div className={`rounded-tl-none rounded-tr-lg rounded-b-lg bg-primary flex flex-col items-start justify-start py-0 px-0`}>
            <div className="flex flex-col items-start justify-start p-4 py-1">
              <div className={`relative text-sm leading-[22px] font-chat-dante-ai-com-1470x705-default-poppins-regular-16 ${theme.colorScheme == "dark" ? "text-white" : "text-black"} text-left inline-block`}>
                <ReactMarkdown>{formattedMessage.text}</ReactMarkdown>
                {formattedMessage.image_url && <Image src={formattedMessage.image_url} radius={"sm"} style={{ width: 500, paddingBottom: '.5rem' }} />}
              </div>
            </div>
          </div>
          <div className="h-12 flex relative flex-col items-start justify-start pt-[8.010000228881836px] pb-0 pr-[231px] pl-0 box-border min-w-[150px] mq450:pr-5 mq450:box-border">
            <div className="flex relative flex-row items-center justify-start pl-0 box-border min-w-[150px] mq450:pr-5 mq450:box-border">
              <div className="rounded-t rounded-b-none flex flex-row items-center justify-start p-2">
                <ActionIcon size={"1rem"} onClick={() => handleCopy(props.message)}><Icon name="copy" size="1rem" /></ActionIcon>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="flex flex-row items-end justify-between gap-4">
        <div className=" flex flex-row gap-4">
          <Image src={'/images/temp-logo.png'} mah={40} maw={40} className="self-center" />
          <div className={`flex flex-col items-start justify-start`}>
            <div className={`text-md leading-[22px] text-left inline-block  border-1/2  rounded-lg bg-white px-8 py-3`}>
              <ReactMarkdown>{props.message}</ReactMarkdown>
            </div>
          </div>
        </div>
        <ActionIcon className="" size={"1rem"} onClick={() => handleCopy(props.message)}><Icon name="copy" size="1rem" /></ActionIcon>
      </div>
    </div>

  );
};


export default BotMessage
