// src/utils/storage.ts
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { UserToken } from 'src/interfaces';

const TOKEN_KEY = 'auth_token';
const ENCRYPTION_KEY = 'ecryptedkeycontextclientreact';

if (!ENCRYPTION_KEY) {
  throw new Error('REACT_APP_ENCRYPTION_KEY is not set in the environment');
}

const encrypt = (data: string): string => {
  return CryptoJS.AES.encrypt(data, ENCRYPTION_KEY).toString();
};

const decrypt = (ciphertext: string): string => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const setStorageToken = (token: UserToken): void => {
  const encryptedToken = encrypt(JSON.stringify(token));
  Cookies.set(TOKEN_KEY, encryptedToken, {
    secure: true,
    sameSite: 'strict',
    expires: 7 // Set expiry to 7 days, adjust as needed
  });
};

export const getStorageToken = (): UserToken | null => {
  const encryptedToken = Cookies.get(TOKEN_KEY);
  if (!encryptedToken) return null;
  
  try {
    const decryptedToken = decrypt(encryptedToken);
    return JSON.parse(decryptedToken);
  } catch (error) {
    console.error('Failed to decrypt token:', error);
    clearStorageToken();
    return null;
  }
};

export const clearStorageToken = (): void => {
  Cookies.remove(TOKEN_KEY);
};