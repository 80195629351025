import { useState, useEffect, useCallback, useMemo } from 'react';
import { PriceItem, GroupedPrices } from "src/interfaces/Payments";
import { ResponseKind } from "src/services/services/MainService";
import { paymentService } from "src/services/services/payment/payment.api";
import { useAppSelector } from "src/redux/redux-store/hooks";

export const usePayments = () => {
  const [prices, setPrices] = useState<PriceItem[] | null>(null);
  const [groupedPrices, setGroupedPrices] = useState<GroupedPrices | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const user = useAppSelector((state) => state.user);

  const fetchPrices = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await paymentService.getPrices();
      if (response.kind === ResponseKind.OK && response.data) {
        setPrices(response.data.prices);
        
        const grouped = response.data.prices.reduce((acc: GroupedPrices, price: PriceItem) => {
          const productName = price.product.name;
          if (!acc[productName]) {
            acc[productName] = [];
          }
          acc[productName].push(price);
          return acc;
        }, {});
        setGroupedPrices(grouped);
      } else {
        setError("Failed to fetch prices");
      }
    } catch (err) {
      setError("An error occurred while fetching prices");
    } finally {
      setLoading(false);
    }
  }, []);

  const createSession = useCallback(async (priceId: string) => {
    try {
      const response = await paymentService.createPaymentSession(priceId);
      if (response.kind === ResponseKind.OK && response.data) {
        return response.data.url;
      } else {
        throw new Error("Failed to create payment session");
      }
    } catch (error) {
      console.error("Error creating payment session:", error);
      throw error;
    }
  }, []);

  const cancelSubscription = useCallback(async () => {
    // Implement cancel subscription logic here
    console.log("Cancelling subscription");
    // You'll need to implement the actual API call to cancel the subscription
  }, []);

  const userPlan = useMemo(() => {
    if (!prices || user.plan === "Free") return null;
    return prices.find(price => price.lookup_key === user.plan) || null;
  }, [prices, user.plan]);

  const navigateToPortal = useCallback(async () => {
    const response = await paymentService.createPortalSession();

    if (response.kind == ResponseKind.OK && response.data) {
      return response.data;
    }
   
}, [user]);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  return {
    prices,
    groupedPrices,
    navigateToPortal,
    loading,
    error,
    fetchPrices,
    createSession,
    cancelSubscription,
    userPlan,
    isPaidUser: user.plan !== "Free",
  };
};