import React, { useState, useRef, useCallback } from 'react';
import { Text, Image, ActionIcon } from '@mantine/core';
import Icon from '../Icons/Icons/Icon';

interface ImageInputFieldProps {
  onChange: (file: File | null) => void;
  error?: string;
}

const ImageInputField: React.FC<ImageInputFieldProps> = ({ onChange, error }) => {
  const [preview, setPreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.onerror = () => {
        console.error('FileReader error:', reader.error);
      };
      reader.readAsDataURL(file);
      onChange(file);
      console.log('File selected:', file.name, file.type, file.size);
    } else {
      setPreview(null);
      onChange(null);
      console.log('No file selected');
    }
  }, [onChange]);

  const handleUploadClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    fileInputRef.current?.click();
  }, []);

  const handleDelete = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setPreview(null);
    onChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    console.log('File deleted');
  }, [onChange]);

  return (
    <div className="mb-4">
      <div className="w-32 h-32 mx-auto bg-gray-100 rounded-full flex items-center justify-center relative">
        <Image
          src={preview || "/images/user-avatar.png"}
          alt={preview ? "Preview" : "Placeholder"}
          width={128}
          height={128}
          className= "border rounded-full overflow-hidden"
          fit="cover"
        />
        <div className="absolute bottom-0 right-0 p-1">
          <ActionIcon 
            radius="xl" 
            className='border border-primary bg-white' 
            onClick={preview ? handleDelete : handleUploadClick}
          >
            <Icon name={preview ? 'trash' : 'pen'} className="w-6 h-6 text-gray-600" />
          </ActionIcon>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
      {error && <Text color="red" size="sm" mt={4} align="center">{error}</Text>}
    </div>
  );
};

export default ImageInputField;