import React from 'react';
import { ActionIcon, Text, ThemeIcon } from "@mantine/core";
import Icon from "../../common/Icons/Icons/Icon";
import { ChatSession } from "src/interfaces/Chat";

interface ReadOnlyChatHistoryTileProps {
  item: ChatSession;
  onPress: (id: string) => void;
  selected: boolean;
}

export const ReadOnlyChatHistoryTile: React.FC<ReadOnlyChatHistoryTileProps> = ({
  item,
  onPress,
  selected
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the parent
    onPress(item.id);
  };

  return (
    <div 
      className={`flex flex-row h-10 justify-between items-center rounded-xl py-1 px-1 my-1 w-[100%] cursor-pointer ${selected ? ' bg-blue-300' : 'bg-gray-100'}`}
      onClick={handleClick}
      style={{ transition: 'all 0.2s ease-in-out' }}
    >
      <div className="flex flex-row justify-center items-center gap-2">
        <ThemeIcon size={"sm"} bg={"white"} radius={"lg"}>
          <Icon color="black" name="brain" size="1rem" />
        </ThemeIcon>
        <Text fw={"bold"} size={"xs"}>
          {item.title}
        </Text>
      </div>
    </div>
  );
};