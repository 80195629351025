import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS } from "src/utils/styles";
import { Notifications } from "@mantine/notifications";
import useResponsive from "src/hooks/useResponsive";
import DynamicLayout from "src/components/Layout/DynamicLayout";
import RegisterChildForm, { RegisterFormData } from "src/components/Forms/RegisterChild";
import ParentRegisterChildSidebar from "src/components/Sidebars/Parent/ParentSidebarRegisterChild";
import useChildrenData from "src/hooks/useChildrenData";
import { Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { parentRoutes } from "src/navigators/routes/ParentRoutes";


const dummySchools = [
  { value: 'school1', label: 'Evergreen Elementary' },
  { value: 'school2', label: 'Sunnydale Middle School' },
  { value: 'school3', label: 'Oakridge High' },
  { value: 'school4', label: 'Riverside Academy' },
  { value: 'school5', label: 'Maple Grove School' },
  { value: 'school6', label: 'Pine Valley Elementary' },
  { value: 'school7', label: 'Cedar Hills Junior High' },
  { value: 'school8', label: 'Willow Creek Preparatory' },
  { value: 'school9', label: 'Birchwood International School' },
  { value: 'school10', label: 'Redwood Montessori' },
  { value: 'school11', label: 'Aspen Heights College' },
  { value: 'school12', label: 'Laurel Springs Charter School' },
  { value: 'school13', label: 'Magnolia Elementary' },
  { value: 'school14', label: 'Cypress Middle School' },
  { value: 'school15', label: 'Sequoia High School' }
];

const RegisterChildScreen = () => {
  const navigate = useNavigate()
  const { backgroundColor, backgroundColorExterior, isMobile, isDesktop } = useResponsive()
  const {children, createChild, deleteChild} = useChildrenData()

  const header = (<div className="flex flex-col">
      <Text size={"lg"} fw={"bold"}>Register Learner</Text>
      <Text className="hidden xl:flex" size={"md"}>Fill in the following details to register learner first.</Text>
    </div>)

  return (
    <DynamicLayout
      heading = {header}
      SidebarComponent={(props) => (
        <ParentRegisterChildSidebar deleteChild={deleteChild} children={children} {...props}/>
      )}
    >
    <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
      <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
        <Notifications
          position={isMobile ? "top-center" : "bottom-right"}
          limit={isMobile ? 1 : 5}
        />
        <div className= 'flex h-full w-full mt-5 xl:mt-0 xl:items-center xl:justify-center'>

        <RegisterChildForm schools={dummySchools} onSubmit={async (values: RegisterFormData, cb = () => {}) => {
          let temp = {
            name: values.firstName + " " + values.lastName,
            level: values.grade,
            username: values.username,
            password: values.password,
        }

          const res = await createChild(temp)               //REITERATE
    
          if(res == true){
            window.location.href = parentRoutes.LEARNER_PORTAL
          }
        }}/>
        </div>

      </StyledContainerInset>
    </StyledContainer>
    </DynamicLayout>
  );
};

export default RegisterChildScreen;
