import React, { forwardRef } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';

interface SelectFieldProps extends Omit<SelectProps, 'error'> {
  label?: string;
  error?: string;
  className?: string;
  selectClassName?: string;
  selectContainerClassName?: string;
  labelClassName?: string;
  errorClassName?: string;
}

const SelectField = forwardRef<HTMLInputElement, SelectFieldProps>(({
  label,
  placeholder,
  error,
  className = '',
  selectContainerClassName = '',
  selectClassName = '',
  labelClassName = '',
  errorClassName = '',
  data,
  ...props
}, ref) => {
  return (
    <div className={`relative ${className}`}>
      <div className={`relative ${selectContainerClassName}`}>
        <Select
          ref={ref}
          placeholder={placeholder}
          data={data}
        
          // rightSection={<ArrowDownCircleIcon  />}
          styles={(theme) => ({
            root: {
              '&:focus-within': {
                borderColor: theme.colors.blue[6],
              },
            },
            input: {
              height: '64px',
              borderRadius: '8px',
              border: `1px solid  ${error ? theme.colors.red[6] : theme.colors.gray[3]}`,
              '&:focus': {
                borderColor: theme.colors.blue[6],
              },
            },
            rightSection: { 
              pointerEvents: 'none',
              color: theme.colors.gray[5],
            },
            dropdown: { 
              borderRadius: '8px',
              border: `1px solid ${theme.colors.gray[3]}`,
            },
            item: {
              '&[data-selected]': {
                '&, &:hover': {
                  backgroundColor: theme.colors.blue[6],
                  color: theme.white,
                },
              },
            },
          })}
          classNames={{
            input: `bg-gray-50 placeholder-gray-400 text-gray-900 text-sm ${selectClassName}`,
            item: 'text-sm',
          }}
          {...props}
        />
      </div>
      {error && (
        <p className={`mt-1 text-sm text-red-600 ${errorClassName}`}>{error}</p>
      )}
    </div>
  );
});

SelectField.displayName = 'SelectField';

export default SelectField;