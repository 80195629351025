import React, { forwardRef, useState, useEffect, InputHTMLAttributes } from 'react';
import Icon from '../Icons/Icons/Icon';
import { ActionIcon } from '@mantine/core';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  inputContainerClassName?: string
  labelClassName?: string;
  errorClassName?: string;
  showPasswordToggle?: boolean;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(({ 
  label,
  type = 'text',
  placeholder,
  error,
  className = '',
  inputContainerClassName = '',
  inputClassName = '',
  labelClassName = '',
  errorClassName = '',
  showPasswordToggle = false,
  ...props
}, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    if (type === 'password') {
      setInputType(showPassword ? 'text' : 'password');
    }
  }, [showPassword, type]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`relative mb-4 ${className}`}>
      <div className={`relative h-16 ${inputContainerClassName}`}>
        <input
          ref={ref}
          type={inputType}
          className={`
            w-full px-4 h-full bg-gray-50 border-1/2 rounded-lg shadow-md
            placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 font-sans
            ${error ? 'border-red-500' : ''}
            ${inputClassName}
          `}
          placeholder={placeholder}
          {...props}
        />
        {showPasswordToggle && type === 'password' && (
          <ActionIcon
            className="absolute inset-y-0 right-0 flex items-center self-center w-10 mr-1"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <Icon name='eyeSlash' className="h-5 w-5 text-gray-400" />
            ) : (
              <Icon name='eye' className="h-5 w-5 text-gray-400" />
            )}
          </ActionIcon>
        )}
      </div>
      {error && (
        <p className={`mt-1 text-sm text-red-600 ${errorClassName}`}>{error}</p>
      )}
    </div>
  );
});

InputField.displayName = 'InputField';

export default InputField;