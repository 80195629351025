import { Center, Text } from "@mantine/core"
import { colors } from "@mui/material"

interface Disclaimer{
    text: string
}

export const Disclaimer = (props: Disclaimer) => {
    const {text} = props

    return(<Center style={{ margin: "0.25rem" }}>
    <Text size={10} style={{ textAlign: "center", color: colors.grey[600] }}>Disclaimer: {text}</Text>
  </Center>)
}