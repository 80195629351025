import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Text } from "@mantine/core";
import { AuthLayout } from 'src/components/Layout/AuthLayout';
import { VisualSideLayout } from 'src/components/Layout/VisualsLayout';
import { UserTypeToggle } from 'src/components/Toggles/UserTypeToggle';
import { LearnerSignInForm } from 'src/components/Forms/LearnerSignin';
import { ParentSignInForm } from 'src/components/Forms/ParentSignin';
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { ErrorKind } from "src/services/services/MainService/apiProblem";
import { useAppDispatch } from 'src/redux/redux-store/hooks';
import { updateAdminSettings, updateAppSettings, updateUser } from 'src/redux/redux-store';
import { UserRole } from 'src/enums/User';
import { userRoutes } from 'src/navigators/routes/user';
import { useAuth } from 'src/hooks/useAuth';
import { authRoutes } from 'src/navigators/routes/auth';
import { AuthHeader } from 'src/components/Cards/AuthHeaderCard';
import { clearStorageToken } from 'src/utils/auth.storage';

const SignIn: React.FC = () => {
  const [userType, setUserType] = useState<'Learner' | 'Parent'>('Learner');
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const {login} = useAuth()

  const navigateToSignUp = useCallback(() => {
    navigate(authRoutes.SIGNUP);
  }, []);

  const navigateToForgotPassword = useCallback(() => {
    navigate(authRoutes.FORGOT_PASSWORD)
  }, [])

  const loginAsChild = useCallback(async (values: any) => {
    setLoading(true);
    try {
      const response = await authService.logIn(
        values.username,
        values.password
      );
      if (response.kind === ResponseKind.OK) {
        const tokenData = response.data;
  
        if (tokenData) {
          if (tokenData.role === UserRole.user) {
            login(tokenData)
            navigate(userRoutes.DASHBOARD);
          } else {
            clearStorageToken()
            alert("Invalid credentials");
            return;
          }
        } else {
          alert("Invalid credentials");
        }
      } else {
        throw new Error("Login failed");
      }
    } catch (e) {
      alert("Invalid credentials");
    } finally {
      setLoading(false);
    }
  }, []);


  const loginAsParent = useCallback(async (values: any) => {
    setLoading(true);
    try {
      const response = await authService.logIn(
        values.email,
        values.password
      );
      if (response.kind === ResponseKind.OK) {
        const tokenData = response.data;
        if (tokenData) {
          if(tokenData.role == UserRole.parent) {
              login(tokenData);
              navigate('/parent')
            } else {
              alert("Invalid credentials");
              clearStorageToken();
              return;
          }
        } else {
          alert("Invalid credentials");
        }
      } else {
        throw new Error("Login failed");
      }
    } catch (e) {
      alert("Invalid credentials");
    } finally {
      setLoading(false);
    }
  }, []);

  const formComponent = (
    <div className="w-full max-w-md space-y-6">
      <AuthHeader title='Sign In' subtitle='Enter your details to sign in' />
      <UserTypeToggle userType={userType} onToggle={setUserType} />
      {userType === 'Learner' ? (
        <LearnerSignInForm handleForgotPassword={navigateToForgotPassword} onSubmit={loginAsChild} loading={loading} error={error} />
      ) : (
        <ParentSignInForm handleForgotPassword={navigateToForgotPassword} onSubmit={loginAsParent} loading={loading} error={error} />
      )}
      <Text className="text-center text-sm">
        Don't have an account? <a onClick={navigateToSignUp} className="text-blue-600">Sign Up</a>
      </Text>
    </div>
  );

  return (
    <AuthLayout
      visualComponent={<VisualSideLayout/>}
      formComponent={formComponent}
    />
  );
};

export default SignIn;


