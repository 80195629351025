import React from 'react';
import { Card, Text } from '@mantine/core';
import ChildCard from 'src/components/Cards/ChildCard';
import { ChildrenData } from 'src/interfaces/Children';
import Icon from '../../Icons/Icons/Icon';

interface ChildCardListProps {
  children: ChildrenData[];
  onAddChild: () => void;
  onDeleteChild: (name: string) => void;
}

const ChildCardList = ({ children, onAddChild, onDeleteChild }: ChildCardListProps) => {
  return (
    <div className="flex flex-col h-full max-w-7xl">
      <h2 className="text-lg font-medium p-6 pb-4">Registered Learners</h2>
      <div className="flex-grow overflow-auto p-6 pt-0">
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-items-center">
          {children.map((child, index) => (
            <ChildCard key={child.id} colorIndex={index} child={child} onDelete={onDeleteChild} />
          ))}
          <Card withBorder className="flex flex-col items-center w-full rounded-xl p-8">
            <div className="w-24 h-24 rounded-full bg-blue-100 flex items-center justify-center mb-3">
              <Icon name='addUser' size='40' color='darkBlue' />
            </div>
            <Text size="lg" weight={500} className="mb-4">Add Another Learner</Text>
            <button
              className="mt-auto w-full px-4 py-2 text-sm text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200 transition-colors"
              onClick={onAddChild}
            >
              Add
            </button>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ChildCardList;