// AccountView.tsx
import React, { useState } from 'react';
import { Card, Text, Button } from '@mantine/core';
import ChangePasswordModal from '../Modals/ChangePasswordModal';

interface AccountViewProps {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    avatar: string;
  };
}

export const AccountView: React.FC<AccountViewProps> = ({ user }) => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  return (
    <div className='my-4 w-1/2'>
      <div className="flex items-center mb-6">
        <div className="w-16 h-16 bg-gray-200 rounded-full mr-4">
          {user.avatar ? (
            <img src={user.avatar} alt={`${user.firstName} ${user.lastName}`} className="w-full h-full rounded-full object-cover" />
          ) : (
            <div className="w-full h-full flex items-center justify-center text-2xl font-bold text-gray-400">
              {user.firstName.charAt(0).toUpperCase()}
            </div>
          )}
        </div>
        <div>
          <Text size="xl" weight={700}>{`${user.firstName} ${user.lastName}`}</Text>
          <Text size="sm" color="dimmed">{user.username}</Text>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <Text size="sm" weight={500} className="mb-1">Name</Text>
          <div className="flex space-x-2">
            <Text className="flex-1 p-2 bg-gray-100 rounded">{user.firstName}</Text>
            <Text className="flex-1 p-2 bg-gray-100 rounded">{user.lastName}</Text>
          </div>
        </div>
        <div>
          <Text size="sm" weight={500} className="mb-1">Email Address</Text>
          <Text className="p-2 bg-gray-100 rounded">{user.email}</Text>
        </div>
        <div>
          <Text size="sm" weight={500} className="mb-1">Plan</Text>
          <Text className="p-2 bg-gray-100 rounded">{user.username}</Text>
        </div>
        <div>
          <Text size="sm" weight={500} className="mb-1">Password</Text>
          <div className="flex items-center justify-between">
            <Text className="p-2 bg-gray-100 rounded flex-grow mr-2">••••••••</Text>
            <Button variant="outline" onClick={() => setIsPasswordModalOpen(true)}>
              Change Password
            </Button>
          </div>
        </div>
      </div>

      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
      />
    </div>
  );
};
