import { Button, ButtonProps, ButtonStylesNames, ButtonStylesParams, Styles, Text, useMantineTheme } from "@mantine/core";
import { IconName } from "../Icons/Icons/iconRegistry";
import Icon from "../Icons/Icons/Icon";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import { useColorScheme } from "@mantine/hooks";
import { colors } from "@mui/material";

interface FocusButton {
  icon: IconName;
  text: string;
  iconSize?: string;
  className?: string
  w?: string;
  textSize?: number;
  loading?: boolean,
  onClick?: () => void,
  focused?: boolean
}

export const FocusButton = (props: FocusButton) => {
  const { text, icon, iconSize = "1rem", textSize = FONT_SIZES.text, onClick, focused, w, className } = props;
  const theme = useMantineTheme()

  return (
      <Button className={className} onClick={onClick} radius={10} styles={(theme) => ({
        root: {
            width: w || '100%',
            transition: 'transform 0.3s ease-in-out, background 0.3s ease-in-out', // Smooth transitions for transform and background changes
            ...(focused ? {
              transform: 'scale(1.02)', // Enlarge the button when focused
              background: theme.colors.blue[8],
            } : {
              backgroundColor: COLORS.primary,
              '&:not([data-disabled])': theme.fn.hover({
                backgroundColor: theme.fn['darken'](theme.colorScheme === 'light' ? COLORS.lightButton : COLORS.darkButton, 0.05),
              }),
            }),
          },
          inner: {
            justifyContent: 'flex-start',
          },
    }) } leftIcon={<Icon size={iconSize} color={focused ? "white" : theme.colorScheme == "dark" ? "white" : "black"} name={icon} />}>
        <Text style={{fontSize: textSize, color: focused ? "white" : theme.colorScheme == "dark" ? "white" : "black"}}>{text}</Text>
      </Button>
  );
};
