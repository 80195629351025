// import React, { useRef, useEffect, useState } from 'react';
// import { 
//   Textarea, 
//   ActionIcon, 
//   Text,
//   Button,
//   Group,
//   Box,
// } from '@mantine/core';
// import { ArrowForward } from '@mui/icons-material';
// import { COLORS } from 'src/utils/styles';
// import Icon from '../Icons/Icons/Icon';
// import { FileTile } from 'src/components/Chat/FileTile';
// import FileUploadModal from 'src/components/Modals/FileUpload';



// interface ChatInputProps {
//   inputMessage: string;
//   uploadedFile: File | null;
//   setUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
//   setInputMessage: (message: string) => void;
//   onSend: (file?: File) => void;
//   disabled: boolean;
//   minRows?: number;
//   hide: boolean;
//   fileUploading?: boolean;
//   maxRows?: number;
//   streaming: boolean;
//   onStopStream: () => void;
// }

// const ChatInput: React.FC<ChatInputProps> = (props) => {
//   const {
//     inputMessage,
//     setInputMessage,
//     onSend,
//     disabled,
//     fileUploading,
//     uploadedFile,
//     hide,
//     setUploadedFile,
//     minRows,
//     maxRows,
//     streaming,
//     onStopStream,
//   } = props;

//   const textareaRef = useRef<HTMLTextAreaElement | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedFile, setSelectedFile] = useState<File | null>(null);

//   useEffect(() => {
//     const textarea = textareaRef.current;
//     if (textarea) {
//       textarea.scrollTop = textarea.scrollHeight;
//     }
//   }, [inputMessage]);


//   const deleteUserFile = () => {
//     setUploadedFile(null);
//   };

//   return (
//     <div className="mb-2 relative">
//       {uploadedFile && (
//         <FileTile file={uploadedFile} onRemove={deleteUserFile} />
//       )}
//       <div className="flex items-center flex-row gap-2">
//         <Textarea
//           autosize
//           size="sm"
//           w="100%"
//           minRows={minRows || 1}
//           maxRows={maxRows || 2}
//           value={inputMessage}
//           onChange={(e) => setInputMessage(e.currentTarget.value)}
//           onKeyDown={(e) => {
//             if (e.key === "Enter" && !e.shiftKey) {
//               e.preventDefault();
//               if (!disabled) {
//                 if (uploadedFile) {
//                   onSend(uploadedFile);
//                 } else onSend();
//               }
//             }
//           }}
//           styles={{
//             input: {
//               '&:focus': {
//                 borderColor: COLORS.primary,
//               },
//               paddingRight: '3rem',
//             },
//             rightSection: {
//               marginRight: 10,
//             },
//           }}
//           radius={10}
//           placeholder="Ask your question"
//           rightSection={
//             streaming ? (
//               <ActionIcon
//                 variant="outline"
//                 radius={5}
//                 style={{ color: 'black' }}
//                 onClick={onStopStream}
//               >
//                 <Icon
//                   name="stop"
//                   size="1.3rem"
//                   color="black"
//                 />
//               </ActionIcon>
//             ) : (
//               <div className="flex-row flex">
//                 <ActionIcon variant="light" onClick={() => setIsModalOpen(true)}>
//                   <Icon name="attach" size="1rem" color="black" />
//                 </ActionIcon>
//               </div>
//             )
//           }
//         />
//         <ActionIcon
//           className="bg-primary"
//           disabled={disabled}
//           onClick={() => {
//             if (uploadedFile) {
//               onSend(uploadedFile);
//             } else onSend();
//           }}
//         >
//           <ArrowForward style={{ color: 'white' }} />
//         </ActionIcon>
//       </div>
    
//       <FileUploadModal
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         onFileUpload={(file) => setUploadedFile(file)}
//         allowedFileTypes="image/*,application/pdf"
//         maxFileSize={10 * 1024 * 1024} // 10MB
//         customClasses={{
//           modal: 'bg-white',
//           title: 'text-xl text-gray-800',
//           button: 'bg-blue-500 hover:bg-blue-600 text-white',
//           errorText: 'text-red-600 font-bold',
//         }}
//       />
//     </div>
//   );
// };

// export default ChatInput;


import React from 'react';
import { Textarea, ActionIcon } from '@mantine/core';
import { ArrowForward } from '@mui/icons-material';
import { FileTile } from 'src/components/Chat/FileTile';
import FileUploadModal from 'src/components/Modals/FileUpload';
import Icon from '../Icons/Icons/Icon';
import { COLORS } from 'src/utils/styles';

interface ChatInputProps {
  inputMessage: string;
  setInputMessage: (message: string) => void;
  onSend: (message: string) => void;
  uploadedFile: File | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
  uploadStatus: 'idle' | 'uploading' | 'success' | 'error';
  streaming: boolean;
  disabled: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
  inputMessage,
  setInputMessage,
  onSend,
  uploadedFile,
  setUploadedFile,
  uploadStatus,
  streaming,
  disabled
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleSend = () => {
    if (!disabled) {
      onSend(inputMessage);
    }
  };

  return (
    <div className="mb-2 relative">
      {uploadedFile && uploadStatus == 'idle' && (
        <div className=' w-1/4'><FileTile file={uploadedFile} onRemove={() => setUploadedFile(null)} /></div>
      )}
      <div className="flex items-center flex-row gap-2">
        <Textarea
          value={inputMessage}
          autosize
          size="sm"
          w="100%"
          minRows={1}
          maxRows={2}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (!disabled) {
                 handleSend();
              }
            }
          }}
          styles={{
            input: {
              '&:focus': {
                borderColor: COLORS.primary,
              },
              paddingRight: '3rem',
            },
            rightSection: {
              marginRight: 10,
            },
          }}
          radius={10}
          onChange={(e) => setInputMessage(e.currentTarget.value)}
          placeholder="Ask your question"
          disabled={streaming || uploadStatus === 'uploading'}
          rightSection={
            <ActionIcon variant="light" onClick={() => setIsModalOpen(true)}>
              <Icon name="attach" size="1rem" color="black" />
            </ActionIcon>
          }
        />
        <ActionIcon
          className="bg-primary"
          disabled={disabled || isModalOpen}
          onClick={handleSend}
        >
          <ArrowForward style={{ color: 'white' }} />
        </ActionIcon>
      </div>
      <FileUploadModal
        isOpen={isModalOpen}
        customClasses={{
          modal: 'bg-white',
          title: 'text-xl text-gray-800',
          button: 'bg-blue-500 hover:bg-blue-600 text-white',
          errorText: 'text-red-600 font-bold',
        }}
        onClose={() => setIsModalOpen(false)}
        onFileUpload={(file) => setUploadedFile(file)}
        allowedFileTypes="image/*,application/pdf"
        maxFileSize={10 * 1024 * 1024}
      />
    </div>
  );
};

export default ChatInput;