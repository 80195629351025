import { Badge, Button, Card, List, Text, ThemeIcon } from "@mantine/core";
import Icon from "../common/Icons/Icons/Icon";

interface PlanCardProps {
    title: string;
    price: number;
    description: string;
    features: string[];
    buttonText: string;
    buttonVariant: 'outline' | 'filled';
    buttonColor?: string;
    onButtonClick?: () => void;
    additionalButtons?: React.ReactNode;
    subscriptionDetails?: React.ReactNode;
    isCurrentPlan: boolean;
  }
  
export const PlanCard: React.FC<PlanCardProps> = ({
    title,
    price,
    description,
    features,
    buttonText,
    buttonVariant,
    buttonColor,
    onButtonClick,
    additionalButtons,
    subscriptionDetails,
    isCurrentPlan
  }) => (
    <Card shadow="sm" p="lg" radius="md" withBorder className="w-full mx-auto">
      <div className="flex justify-between items-start mb-2">
        <Text size="xl" className='font-semibold' >{title}</Text>
        {isCurrentPlan && <Badge color="green">Current Plan</Badge>}
      </div>
      <Text size="sm" color="dimmed" className="mb-4">{description}</Text>
      <Text size="xl" weight={700} className="mb-4">
        ${price.toFixed(2)} <span className="text-sm font-normal">/month</span>
      </Text>
      {subscriptionDetails}
      <List
        spacing="sm"
        size="sm"
        icon={
          <ThemeIcon color="blue" size={24} radius="xl">
            <Icon name='check' />
          </ThemeIcon>
        }
      >
        {features.map((feature, index) => (
          <List.Item key={index}>{feature}</List.Item>
        ))}
      </List>
      <Button
        variant={buttonVariant}
        color={buttonColor}
        fullWidth
        onClick={onButtonClick}
        mt="md"
      >
        {buttonText}
      </Button>
      {additionalButtons}
    </Card>
  );