import React, { useCallback, useEffect, useState } from 'react';
import { Card, Text, Button } from '@mantine/core';
import { TabSelector } from 'src/components/Toggles/TabSelectorToggle';
import { AccountView } from 'src/components/Layout/AccountLayout';
import DynamicLayout from 'src/components/Layout/DynamicLayout';
import { StyledContainer } from 'src/components/common/StyledComponents/Container';
import { StyledContainerInset } from 'src/components/common/StyledComponents/BackgroundContainer';
import { Notifications } from '@mantine/notifications';
import useResponsive from 'src/hooks/useResponsive';
import ParentChatSidebar from 'src/components/Sidebars/Parent/ParentSIdebarHome';
import ParentProfileSidebar from 'src/components/Sidebars/Parent/ParentSIdebarProfile';
import { DetailedProOptions } from 'src/components/Layout/ProPlansLayout';
import { GroupedPrices, PriceItem } from 'src/interfaces/Payments';
import { paymentService } from 'src/services/services/payment/payment.api';
import { ResponseKind } from 'src/services/services/MainService';
import { useAppSelector } from 'src/redux/redux-store/hooks';
import { useNavigate } from 'react-router-dom';
import { usePayments } from 'src/hooks/usePayment';

const PricingScreen = () => {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate()
  const { isMobile, backgroundColorExterior, backgroundColor } = useResponsive();
  const {createSession, userPlan, groupedPrices, navigateToPortal} = usePayments()


  const handleCancellation = async () => {
    const url = await navigateToPortal();
    if (url) {
      window.location.href = url;
    }
  }

  const header = (
    <div className="flex flex-col">
      <Text size={"lg"} fw={"bold"}>Profile </Text>
    </div>
  );

  return (
    <DynamicLayout
      heading={header}
      SidebarComponent={(props) => (
        <ParentProfileSidebar
          {...props}
        />
      )}
    >
      <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
        <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
          <Notifications
            position={isMobile ? "top-center" : "bottom-right"}
            limit={isMobile ? 1 : 5}
          />
          <div className='h-full p-8'>
            <DetailedProOptions onCancel={handleCancellation} prices={groupedPrices} onSubscribe={createSession} currentPlan={userPlan} />
          </div>
        </StyledContainerInset>
      </StyledContainer>
    </DynamicLayout>
  );
};

export default PricingScreen;