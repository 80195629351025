// ProfileScreen.tsx
import React, { useState } from 'react';
import { Card, Text, Button } from '@mantine/core';
import { TabSelector } from 'src/components/Toggles/TabSelectorToggle';
import { AccountView } from 'src/components/Layout/AccountLayout';
import DynamicLayout from 'src/components/Layout/DynamicLayout';
import { StyledContainer } from 'src/components/common/StyledComponents/Container';
import { StyledContainerInset } from 'src/components/common/StyledComponents/BackgroundContainer';
import { Notifications } from '@mantine/notifications';
import useResponsive from 'src/hooks/useResponsive';
import ParentChatSidebar from 'src/components/Sidebars/Parent/ParentSIdebarHome';
import ParentProfileSidebar from 'src/components/Sidebars/Parent/ParentSIdebarProfile';
import ManageSubscription from 'src/components/Layout/ManageSubscription';

export const mockUser = {
    id: '12345',
    firstName: 'Hania',
    lastName: 'Shafiq',
    fullName: 'Hania Shafiq',
    username: 'Hania883',
    email: 'hania.shafiq@example.com',
    avatar: '/images/user-avatar.png', // You can replace this with an actual URL or leave it empty
    phone: '+1 (555) 123-4567',
    dateOfBirth: '1990-05-15',
    address: {
      street: '123 Main St',
      city: 'Anytown',
      state: 'ST',
      country: 'United States',
      postalCode: '12345'
    },
    role: 'parent',
    accountCreated: '2023-01-01T00:00:00Z',
    lastLogin: '2024-03-15T10:30:00Z',
    preferences: {
      language: 'en',
      timezone: 'America/New_York',
      notifications: {
        email: true,
        push: false,
        sms: true
      }
    },
    children: [
      {
        id: '67890',
        firstName: 'Ali',
        lastName: 'Shafiq',
        dateOfBirth: '2010-08-20',
        grade: '7th',
        school: 'Anytown Middle School'
      },
      {
        id: '67891',
        firstName: 'Sara',
        lastName: 'Shafiq',
        dateOfBirth: '2012-03-10',
        grade: '5th',
        school: 'Anytown Elementary School'
      }
    ],
    subscription: {
      plan: 'pro',
      startDate: '2023-06-01T00:00:00Z',
      endDate: '2024-05-31T23:59:59Z',
      status: 'active'
    }
  };

const tabs = [
    { id: 'account', label: 'Account' },
    { id: 'subscription', label: 'Subscription' },
];

const ProfileScreen = () => {
    const { isMobile, backgroundColorExterior, backgroundColor } = useResponsive()
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    const header = (<div className="flex flex-col">
        <Text size={"lg"} fw={"bold"}>Profile </Text>
    </div>)
    return (
        <DynamicLayout
            heading={header}
            SidebarComponent={(props) => (
                <ParentProfileSidebar
                    {...props}
                />)}
        >
            <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
                <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
                    <Notifications
                        position={isMobile ? "top-center" : "bottom-right"}
                        limit={isMobile ? 1 : 5}
                    />
                    <div className='flex h-full w-full flex-col p-10'>
                        <TabSelector
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            className="w-72"
                        />
                        {activeTab === 'account' && <AccountView user={mockUser} />}
                        {activeTab === 'subscription' && <ManageSubscription />}
                    </div>
                </StyledContainerInset>
            </StyledContainer>
        </DynamicLayout>
    );
};

export default ProfileScreen









