import React, { useState, useRef, KeyboardEvent, ClipboardEvent } from 'react';

interface SplitCodeInputProps {
  length: number;
  onChange: (code: string) => void;
}

export const SplitCodeInput: React.FC<SplitCodeInputProps> = ({ length, onChange }) => {
  const [code, setCode] = useState(Array(length).fill(''));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  const processInput = (index: number, value: string) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    onChange(newCode.join(''));

    // Move to next input if value is entered
    if (value && index < length - 1) {
      inputs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      // Move to previous input on backspace if current input is empty
      inputs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, length);
    const newCode = [...code];
    pastedData.split('').forEach((char, index) => {
      newCode[index] = char;
    });
    setCode(newCode);
    onChange(newCode.join(''));
    inputs.current[Math.min(pastedData.length, length - 1)]?.focus();
  };

  return (
    <div className="flex justify-between mb-4">
      {code.map((digit, index) => (
        <input
          key={index}
          ref={el => inputs.current[index] = el}
          type="text"
          inputMode="numeric"
          maxLength={1}
          value={digit}
          onChange={e => processInput(index, e.target.value)}
          onKeyDown={e => handleKeyDown(index, e)}
          onPaste={handlePaste}
          className="w-16 h-16 sm:w-12 sm:h-12 text-center text-xl border-1/2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
        />
      ))}
    </div>
  );
};