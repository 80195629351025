import { Button } from '@mantine/core';
import React from 'react';

interface Tab {
  id: string;
  label: string;
}

interface TabSelectorProps {
  tabs: Tab[];
  activeTab: string;
  setActiveTab: (tabId: string) => void;
  className?: string;
}

export const TabSelector: React.FC<TabSelectorProps> = ({ 
  tabs, 
  activeTab, 
  setActiveTab, 
  className = '' 
}) => (
  <div className={`flex mb-4 ${className}`}>
    {tabs.map((tab) => (
      <Button
        radius={0}
        key={tab.id}
        variant="white"
        className={`
          border-0
          flex-1
          text-lg
          py-2
          text-center
          border-b-2
          transition-colors
          duration-300
          ${activeTab === tab.id 
            ? 'border-primary text-primary' 
            : 'border-transparent text-black hover:bg-gray-100'
          }
        `}
        onClick={() => setActiveTab(tab.id)}
      >
        {tab.label}
      </Button>
    ))}
  </div>
);

export default TabSelector;