import React, { useState, useEffect } from 'react';
import { Button, Card, Text, Tabs, Badge } from "@mantine/core";
import { GroupedPrices, PriceItem } from "src/interfaces/Payments";
import { ResponseKind } from "src/services/services/MainService";
import { paymentService } from "src/services/services/payment/payment.api";
import { ProPlanCard } from '../Cards/ProPlanCard';

const productDisplayNames: { [key: string]: string } = {
  "Basic": "1 child",
  "Standard": "3 children",
  "Premium": "5 children"
};

interface DetailedProOptionsProps {
  prices: GroupedPrices | null;
  onSubscribe: (priceId: string) => void;
  onCancel: () => void;
  currentPlan: PriceItem | null;
}

export const DetailedProOptions: React.FC<DetailedProOptionsProps> = (props) => {
  const { prices, onSubscribe, onCancel, currentPlan } = props;
  const [activeTab, setActiveTab] = useState<string | null>("Basic");

  useEffect(() => {
    if (currentPlan) {
      setActiveTab(currentPlan.product.name);
    }
  }, [currentPlan]);

  const getIntervalLabel = (intervalCount: number) => {
    switch (intervalCount) {
      case 1: return 'Monthly';
      case 6: return '6 Months';
      case 12: return 'Yearly';
      default: return `${intervalCount} Months`;
    }
  };

  return (
    <div className="my-4">
      <Text size="xl" weight={500} className="mb-4">Choose Your Pro Plan</Text>
      {prices && (
        <Tabs value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            {Object.keys(prices).map((productName) => (
              <Tabs.Tab key={productName} value={productName}>
                {productDisplayNames[productName] || productName}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {Object.entries(prices).map(([productName, productPrices]) => (
            <Tabs.Panel key={productName} value={productName}>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                {productPrices
                  .sort((a, b) => a.recurring.interval_count - b.recurring.interval_count)
                  .map((price) => (
                    <ProPlanCard
                      key={price.id}
                      title={getIntervalLabel(price.recurring.interval_count)}
                      price={price.unit_amount / 100}
                      description={price.product.description}
                      onSubscribe={() => onSubscribe(price.id)}
                      onCancel={() => onCancel()}
                      isCurrentPlan={currentPlan?.id === price.id}
                    />
                  ))}
              </div>
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
    </div>
  );
};