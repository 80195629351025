import { Image, Text } from "@mantine/core"

interface AuthHeader{
    title: string
    subtitle: string
}

export const AuthHeader = (props: AuthHeader) => {
    const {title, subtitle} = props
    
    return(
        <div className='flex flex-row gap-4 items-center'>
        <div className='w-16 flex flex-col lg:hidden'>
        <Image src="/Innovation-pana 1.png" />
        <Image src="/headline.png" />
        </div>
        <div className='flex flex-col '>
            <Text className="text-xxxl font-extrabold text-gray-800">{title}</Text>
            <Text className="text-gray-600">{subtitle}</Text>
          </div>
        </div>
    )
}