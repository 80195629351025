import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PaymentFeedback from 'src/screens/payment/Feedback';
import ParentDashboard from 'src/screens/parent/Dashboard';
import RegisterChild from 'src/screens/parent/RegisterChild';
import RegisterChildScreen from 'src/screens/parent/RegisterChild';
import ChildView from 'src/screens/parent/ChildView';
import LearnerPortalScreen from 'src/screens/parent/LearnerPortal';
import ProfileScreen from 'src/screens/parent/Profile';
import { DetailedProOptions } from 'src/components/Layout/ProPlansLayout';
import PricingScreen from 'src/screens/parent/PricingPlans';


const baseRoute = "/parent"

export const parentRoutes = {
    DASHBOARD: `dashboard`,
    REGISTER_CHILD: `registerChild`,
    LEARNER_PORTAL:  `learnerPortal`,
    PROFILE: `profile`,
    PAYMENT: `payment`,
    CHILD_VIEW: `childView`,
    PAYMENT_FEEDBACK: `payment/feedback`,
}


const ParentRoutes = () => {

  return (
    <Routes>
      {/* <Route path={"/"} element={<AdminDashboard />} /> */}
      {/* <Route index element={<AdminDashboard />} /> */}
      {/* <Route path={adminRoutes.DASHBOARD} element={<AdminDashboard />} /> */}
      <Route path={parentRoutes.DASHBOARD} element={<ParentDashboard />} />
      <Route path={parentRoutes.REGISTER_CHILD} element={<RegisterChildScreen />} />
      <Route path={parentRoutes.LEARNER_PORTAL} element={<LearnerPortalScreen />} />
      <Route path={parentRoutes.PROFILE} element={<ProfileScreen />} />
      <Route path={parentRoutes.CHILD_VIEW} element={<ChildView />} />
      <Route path={parentRoutes.PAYMENT} element={<PricingScreen />} />
      <Route path={parentRoutes.PAYMENT_FEEDBACK} element={<PaymentFeedback />} />
      {/* Redirect to the admin dashboard if an unknown admin route is accessed */}
      <Route path="/" element={<Navigate replace to={parentRoutes.DASHBOARD} />} />
    </Routes>
  );
};

export default ParentRoutes;