import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ChatArea } from "src/components/ChatArea";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
  BackgroundImage,
  Button,
  Card,
  Center,
  Container,
  LoadingOverlay,
  Text,
  Transition,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { UserRole } from "src/enums/User";
import { Navigate, useNavigate } from "react-router-dom";
import { getRole } from "src/utils/storage";
import {
  handleUpdateAppSettings,
  handleUserUpdate,
  stopStream,
  updateAzureToken,
} from "src/redux/redux-store";
import { ChatStyledContainerInset, StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS } from "src/utils/styles";
import { Notifications } from "@mantine/notifications";
import ChatInput from "src/components/common/Inputs/ChatMessageInput";
import { useChat } from "src/hooks/useChat";
import { useNotifications } from "src/hooks/useNotifications";
import { NotificationTypes, PubSubNotificationTypes } from "src/enums/Notifications";
import useResponsive from "src/hooks/useResponsive";
import { userRoutes } from "src/navigators/routes/user";
import { sendFailureNotification } from "src/utils/notifications";
import Icon from "src/components/common/Icons/Icons/Icon";
import { colors } from "@mui/material";
import { Disclaimer } from "src/components/Chat/Disclaimer";
import { useFileUpload } from "src/hooks/useFileUpload";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const theme = useMantineTheme();
  const sessionId = useAppSelector(state => state.chat.selectedSession)
  const appSettings = useAppSelector((state) => state.app).appSettings;
  const { backgroundColor, backgroundColorExterior, isMobile, isDesktop } = useResponsive()
  const { uploadStatus, uploadedFile, setUploadedFile, uploadFile, client, setUploadStatus } = useFileUpload();
  const {
    inputMessage,
    setInputMessage,
    sendMessage,
    getMessages,
    messages,
    question_mode,
    chatLoading,
    streaming,
    suggestQuestion,
    triggerMessageForFileUpload,
    getSessions,
  } = useChat();

  useEffect(() => {
    if (sessionId) {
      getMessages(sessionId.id);
    }
  }, [sessionId]);

  const handleSend = async (message: string) => {
    let fileUploaded = false;
    let idSession = sessionId
    if (uploadedFile) {
      try {
        triggerMessageForFileUpload(message, uploadedFile)
        fileUploaded = await uploadFile(uploadedFile, idSession.id);
        if (!fileUploaded) {
          console.error('File upload failed');
          // Show error message to user
          sendFailureNotification(NotificationTypes.fileUpload, "File upload failed. Please try again.");
          return; // Exit the function if file upload failed
        }
      } catch (error) {
        console.error('Error during file upload:', error);
        sendFailureNotification(NotificationTypes.fileUpload, "An error occurred during file upload. Please try again.");
        return; // Exit the function if an error occurred
      }
    }

    // Only proceed with sending the message if there's no file or if the file upload was successful
    if (!uploadedFile || fileUploaded) {
      try {
        await sendMessage(message, fileUploaded);
        setUploadedFile(null);
        setInputMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
        sendFailureNotification(NotificationTypes.chatSession, "Failed to send message. Please try again.");
      }finally{
        setUploadStatus('idle')
      }
    }
  };

  // Effect for handling app settings updates
  useEffect(() => {
    if (client) {
      const handleServerMessage = (data: any) => {
        if (data.message.data) {
          const message = JSON.parse(data.message.data.toString());
          if (message.event_type === PubSubNotificationTypes.appInfoUpdate) {
            dispatch(handleUpdateAppSettings());
          }
        }
      };

      client.on("server-message", handleServerMessage);
      return () => {
        client.off("server-message", handleServerMessage);
      };
    }
  }, [client, dispatch]);

  useEffect(() => {
    const initializeComponent = async () => {
      // await checkRole();
      getAllData();
      getSessions();
    };

    initializeComponent();
  }, []);


  const checkFreeTrial = () => {
    // if (user.trial_expired && !user.paid) {
    //   navigate(userRoutes.PAYMENT)
    // }
  }

  const getAllData = useCallback(() => {
    dispatch(handleUserUpdate());
    dispatch(handleUpdateAppSettings());
    checkFreeTrial();
    // getAzureToken()
  }, [dispatch]);

  // const handleStopStream = useCallback(() => {
  //   dispatch(stopStream(true));
  // }, [dispatch]);

  const handleSuggestQuestion = useCallback((option: boolean) => {
    if (option) {
      suggestQuestion(true, sessionId.id)
    } else {
      suggestQuestion(false, sessionId.id)
    }
  }, [sessionId])

  let heightStyle;
  if (!isDesktop) {
    // Is tablet or mobile (Max width 1024px)
    heightStyle = 'calc((var(--vh, 1vh) * 100) - 40px)';
  } else {
    // Desktop (Min width 1024px)
    heightStyle = 'calc((var(--vh, 1vh) * 100) - 70px)';
  }

  return (
    <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
      <ChatStyledContainerInset theme={{ backgroundColor: backgroundColor }}>
        <BackgroundImage src="/images/doodles.png">
          <Notifications
            position={isMobile ? "top-center" : "bottom-right"}
            limit={isMobile ? 1 : 5}
          />
          {/* <ErrorMessage error={error ? error : ""} handleClose={dismissError} />  */}
          <LoadingOverlay visible={chatLoading} />
          <div
            style={{ height: heightStyle }}
            className={`flex flex-col lg:w-[800px] mx-auto overflow-hidden relative`}>
            <ChatArea messages={messages} streaming={streaming} />
            <div className="flex items-center px-4 mb-1">
              <div className="w-[100%]">
                <Transition mounted={question_mode} transition="fade" duration={500} timingFunction="ease">
                  {(styles) => <Card style={styles}>
                    <div className="flex flex-row gap-4 items-center justify-center" >
                      {/* <Text>Do you want to test your knowledge?</Text> */}
                      <Card bg={theme.colors.dark[5]}
                        w={"100%"}
                        onClick={() => { handleSuggestQuestion(false) }}
                        style={{ cursor: "pointer" }}
                        withBorder py={4}>
                        <div className="flex flex-row gap-1 justify-between items-center">
                          <Text style={{ color: 'white' }} size={"sm"}>Continue asking more questions</Text>
                          <Icon color="white" name="question" size="1.1rem" />
                        </div></Card>
                      <Card
                        w={"100%"}
                        onClick={() => { handleSuggestQuestion(true) }}
                        style={{ cursor: "pointer" }} bg={COLORS.primary}
                        withBorder py={4}>
                        <div className="flex flex-row gap-1 justify-between items-center"><Text size={"sm"}>Test my knowledge</Text><Icon name="qa" size="1.1rem" /></div></Card>

                    </div></Card>}
                </Transition>
                <ChatInput
                  inputMessage={inputMessage}
                  setInputMessage={setInputMessage}
                  onSend={handleSend}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  uploadStatus={uploadStatus}
                  streaming={streaming}
                  disabled={streaming || !inputMessage}
                />

                <Disclaimer text={appSettings?.disclaimers} />
              </div></div>
          </div>
        </BackgroundImage>
      </ChatStyledContainerInset>
    </StyledContainer>
  );
};

export default Dashboard;
