import { ChatBubbleOvalLeftIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ActionIcon } from "@mantine/core";
import { ChatSession } from "src/interfaces/Chat";

interface ChatHistoryTileProps {
  item: ChatSession;
  selected: boolean;
  onPress: (chatSession: ChatSession) => void;
  onPressDelete: (id: string) => void;
}

const ChatHistoryTile: React.FC<ChatHistoryTileProps> = ({ item, selected, onPress, onPressDelete }) => (
  <div className={`flex items-center justify-between p-2 my-1 rounded ${selected ? 'bg-gray-200' : 'hover:bg-gray-100'}`}>
    <div 
      className="flex items-center flex-grow cursor-pointer"
      onClick={() => onPress(item)}
    >
      <ChatBubbleOvalLeftIcon className="mr-2 size-5" />
      <span className="text-sm truncate">{item.title}</span>
    </div>
    <ActionIcon onClick={(e) => {
      e.stopPropagation();
      onPressDelete(item.id);
    }} className="text-red-500 hover:text-red-700">
       <TrashIcon className="mr-2 size-5" />
    </ActionIcon>
  </div>
);

export default ChatHistoryTile