import React from 'react';
import { ActionIcon, Image, useMantineTheme } from '@mantine/core';
import ReactMarkdown from 'react-markdown';
import Icon from '../common/Icons/Icons/Icon';
import { isValidJSON } from 'src/utils/helpers';

interface ReadOnlyBotMessageProps {
  message: string;
  index: number;
  onFlagMessage: (index: number) => void;
}

const ReadOnlyBotMessage: React.FC<ReadOnlyBotMessageProps> = ({
  message,
  index,
  onFlagMessage,
}) => {
  const theme = useMantineTheme();


  if (isValidJSON(message)) {
    const formattedMessage = JSON.parse(message)
    return (
      <div className="w-full dsm:w-2/3">
      <div className="flex flex-row items-end justify-between gap-4">
        <div className="flex flex-row gap-4">
        <img src='/images/temp-logo.png' alt="Bot" className="self-center h-10 w-10" />
            <div className="flex flex-col items-start justify-start p-4 py-1">
              <div className={`text-md leading-[22px] text-left inline-block border-1/2 rounded-lg bg-white px-8 py-3`}>
                <ReactMarkdown>{formattedMessage.text}</ReactMarkdown>
                {formattedMessage.image_url && <div className='w-full border p-2 mt-2'><Image src={formattedMessage.image_url} /></div>}
              </div>
            </div>
          </div>
        </div>
        </div>
    )
  }

  return (
    <div className="w-full">
      <div className="flex flex-row items-end justify-between gap-4">
        <div className="flex flex-row gap-4">
          <img src='/images/temp-logo.png' alt="Bot" className="self-center h-10 w-10" />
          <div className="flex flex-col items-start justify-start">
            <div className={`text-md leading-[22px] text-left inline-block border-1/2 rounded-lg bg-white px-8 py-3`}>
              <ReactMarkdown>{message}</ReactMarkdown>
            </div>
          </div>
        </div>
        <ActionIcon size="1rem" onClick={() => onFlagMessage(index)}>
          <Icon name="flag" size="1rem" />
        </ActionIcon>
      </div>
    </div>
  );
};

export default ReadOnlyBotMessage;