import React, { ButtonHTMLAttributes } from 'react';
import { IconName } from '../Icons/Icons/iconRegistry';
import Icon from '../Icons/Icons/Icon';
import { Loader, Text } from '@mantine/core';

type ButtonVariant = 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'link';
type ButtonSize = 'default' | 'sm' | 'lg' | 'md';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  textClass?: string;
  size?: ButtonSize;
  leftIcon?: IconName;
  alignment?: "center" | "left"
  loading?: boolean;
  rightIcon?: IconName;
}

const getVariantClasses = (variant: ButtonVariant = 'default'): string => {
  switch (variant) {
    case 'default':
      return 'bg-primary text-white hover:bg-navy-800 focus:ring-navy-500 shadow-sm';
    case 'destructive':
      return 'bg-red-500 text-white hover:bg-red-600 focus:ring-red-500';
    case 'outline':
      return 'bg-transparent border border-gray-300 text-gray-700 hover:bg-gray-100 focus:ring-gray-500';
    case 'secondary':
      return 'bg-blue-100 text-primary focus:ring-gray-500';
    case 'ghost':
      return 'bg-transparent text-gray-600 focus:outline-none border-0 focus:ring-0';
    case 'link':
      return 'bg-transparent text-blue-500 hover:underline focus:ring-blue-500';
  }
};

const getTextVariantClasses = (variant: ButtonVariant = 'default'): string => {
  switch (variant) {
    case 'default':
      return 'text-white';
    case 'destructive':
      return 'text-white ';
    case 'outline':
      return ' text-gray-700';
    case 'secondary':
      return 'text-gray-800';
    case 'ghost':
      return 'text-gray-600 ';
    case 'link':
      return 'text-blue-500';
  }
};

const getSizeClasses = (size: ButtonSize = 'default'): string => {
  switch (size) {
    case 'default':
      return 'h-12 px-4 py-2 text-lg font-bold';
    case 'sm':
      return 'h-8 px-3 py-1 text-xs';
    case 'md':
      return 'h-10 px-4 py-6 text-lg';
    case 'lg':
      return 'h-14 px-6 py-3 text-base';
  }
};

export const CustomButton: React.FC<ButtonProps> = ({
  className = '',
  variant = 'default',
  size = 'default',
  leftIcon: LeftIcon,
  alignment = 'center',
  loading = false,
  textClass = '',
  rightIcon: RightIcon,
  children,
  ...props
}) => {

  const baseClasses = `inline-flex ${alignment == "left" ? '' : 'justify-center'} items-center w-full rounded-xl transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none`;
  const variantClasses = getVariantClasses(variant);
  const variantTextClasses = getTextVariantClasses(variant);
  const sizeClasses = getSizeClasses(size);

  return (
    <button
      className={`${className} ${baseClasses} ${variantClasses} ${sizeClasses}`}
      {...props}
    >
      {/* <div className='flex flex-row justify-center items-center'> */}
      {LeftIcon && <Icon name={LeftIcon} className="mr-2 -ml-2 h-5 w-5" />}
      {loading ? (
        <Loader color="white" size="sm" />
      ) : (
        <Text className={`font-bold ${variantClasses} bg-inherit ${className}`}>{children}</Text>
      )}
      {RightIcon && <Icon name={RightIcon} className="ml-2 -mr-1 h-5 w-5" />}
      {/* </div> */}

    </button>
  );
};