import Config from "src/config";
import { api, Api } from "../MainService/api";
import { ErrorKind } from "../MainService/apiProblem";
import { ChatResponse, MessageList } from "./chat.api.types";
import { clearRole, clearToken, getToken, setToken } from "src/utils/storage";
import { UserToken } from "src/interfaces";
import { authService } from "../auth/auth.api";
import { ResponseKind } from "../MainService";
import { ChatSession } from "src/interfaces/Chat";

const CHAT_URL = Config.CHAT_URL;

export class ChatApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getAllSessions() {
    try {
      const response = await this.api.apisauce.get<{chat_sessions: ChatSession[]}>(
        `${CHAT_URL}/get-all-chat-session`
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async createNewSession(session_title: string) {
    try {
      const response = await this.api.apisauce.post<ChatSession>(
        `${CHAT_URL}/create-chat-session?session_title=${session_title}`
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async updateSessionTitle(session_title: string, session_id: string) {
    try {
      const response = await this.api.apisauce.post(
        `${CHAT_URL}/modify-chat-session-title?chat_session_id=${session_id}&new_session_title=${session_title}`
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async deleteSession(session_id: string) {
    try {
      const response = await this.api.apisauce.delete(
        `${CHAT_URL}/delete-chat-session?chat_session_id=${session_id}`
      );
      console.log("response from delete", response)
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async getAllMessages(sessionId: string) {
    try {
      const response = await this.api.apisauce.get<MessageList>(
        `${CHAT_URL}/get-chat-session-msgs?chat_session_id=${sessionId}`
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async sendMessage(user_msg: string, file: boolean, session_id: string) {
    try {
      // let parsedToken;
      // const token = await getToken();

      // if (token) {
      //   const todaysDate = new Date();
      //   const tokenExpiry = new Date(token?.expires_at);

      //   if (!(tokenExpiry.getTime() > todaysDate.getTime())) {
      //     const refresh = await authService.getRefreshToken(token.refresh_token);
      //     if (refresh.kind == ResponseKind.OK && refresh?.data) {
      //       const refresh_token = refresh.data;
      //       setToken(refresh_token)
      //       parsedToken = refresh_token?.access_token;
      //     }
      //   } else {
      //     parsedToken = token.access_token;
      //   }
      // }

      // if (!parsedToken) {
      //   throw new Error("No access token available");
      // }
      const response = await this.api.apisauce.post<{ai_response: string}>(
        `${CHAT_URL}/send-msg`,
        {user_msg: user_msg, is_new_doc_uploaded: file, session_id: session_id}
      );
      let data = await this.api.getResponse(response);
      console.log("send message repsonse ", response)
      // const response: any = await fetch(`${CHAT_URL}/send-msg`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${parsedToken}`,
      //   },
      //   body: JSON.stringify({
      //     user_msg: user_msg,
      //     // traceless: traceless,
      //     // mode: mode,
      //   }),
      // });
      // debugger
      // const reader = response.body.getReader();

      return data;
    } catch (e) {
      // alert("Your link is bad")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async getSuggestedQuestion(check: boolean, session_id: string) {
    try {
      const response = await this.api.apisauce.post<{ai_response: string}>(
        `${CHAT_URL}/update-user-question-mode/${check}?chat_session_id=${session_id}`
      );
      console.log("Response from new api ", response)
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async clearChat() {
    try {
      const response = await this.api.apisauce.delete(`${CHAT_URL}/clear-chat`);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }
}

export const chatService = new ChatApi(api);
