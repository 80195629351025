import React, { useMemo } from 'react';
import {
  Navbar,
  Text,
  createStyles,
  useMantineTheme,
  ActionIcon,
} from "@mantine/core";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { useNavigate } from "react-router-dom";
import { clearChatState, clearMessages, resetUser, setSelectedSession, updateChatLoading } from "src/redux/redux-store";
import { chatService } from "src/services/services/chat/chat.api";
import { ResponseKind } from "src/services/services/MainService";
import { confirmDeletionModal } from "./Modals/ConfirmationModal";
import useViewportHeight from "src/hooks/useViewPortHeight";
import { useChat } from "src/hooks/useChat";
import { ChatSession } from "src/interfaces/Chat";
import Icon from "./common/Icons/Icons/Icon";
import { CustomButton } from "./common/Buttons/CustomButton";
import { ChatBubbleOvalLeftIcon, TrashIcon } from '@heroicons/react/24/outline';
import ChatHistoryTile from './Chat/HistoryTile';
import { authService } from 'src/services/services/auth/auth.api';
import { authRoutes } from 'src/navigators/routes/auth';
import { clearToken } from 'src/utils/storage';
import { useAuth } from 'src/hooks/useAuth';
import { ChatSessionsList } from './Chat/ChatSessionsList';

interface SideBarProps {
  opened: boolean;
}

const useStyles = createStyles((theme) => ({
  parentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.colorScheme === "dark" ? "black" : "white",
  },
  navbar: {
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 1024px)": {
      height: "100%",
      borderRadius: "0 0 0 0",
    },
    height: "100%",
    backgroundColor: theme.colorScheme === "dark" ? "black" : theme.white,
  },
}));

const Sidebar: React.FC<SideBarProps> = ({ opened }) => {
  const chatSessions = useAppSelector((state) => state.chat.sessions);
  const selectedSession = useAppSelector((state) => state.chat.selectedSession);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const { newSession, deleteSession } = useChat();
  const theme = useMantineTheme();
  const { logout } = useAuth()

  useViewportHeight();

  const groupedChats = useMemo(() => {
    const now = new Date();
    const today = now.toDateString();
    const yesterday = new Date(now.setDate(now.getDate() - 1)).toDateString();

    return chatSessions.reduce<Record<string, ChatSession[]>>((acc, chat) => {
      const chatDate = new Date(chat.created_at);
      let group: string;

      if (chatDate.toDateString() === today) {
        group = 'Today';
      } else if (chatDate.toDateString() === yesterday) {
        group = 'Yesterday';
      } else {
        group = 'Last Week';
      }

      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(chat);
      return acc;
    }, {});
  }, [chatSessions]);

  const selectSession = async (chatSession: ChatSession) => {
    dispatch(setSelectedSession(chatSession));
  };

  const logOut = async () => {
    const response = await authService.signOut();

    if (response.kind == ResponseKind.OK) {
      navigate(authRoutes.LOGIN, { replace: true });
      clearToken();
      dispatch(resetUser());
      dispatch(clearChatState());
      logout();
    }
  };

  return (
    <Navbar
      hiddenBreakpoint={992}
      withBorder={false}
      px="xs"
      className={classes.parentContainer}
      hidden={!opened}
      width={{
        md: 350,
      }}
    >
      <div className="flex flex-col h-screen text-gray-800 w-full p-4">
        <div className="flex items-center space-x-3 my-5">
          <div className="w-12 h-12 bg-orange-500 rounded-full flex items-center justify-center text-white font-bold text-xl">
            {user.name.charAt(0).toUpperCase()}
          </div>
          <div>
            <Text className="font-bold">{user.name}</Text>
            <Text className="text-sm text-gray-600">Primary 5 Science</Text>
          </div>
        </div>

        <ChatSessionsList 
        groupedChats={groupedChats} 
        onNewSession={newSession} 
        onDeleteSession={deleteSession} 
        onSelectSession={selectSession} 
        selectedSession={selectedSession.id} />

        <CustomButton
          onClick={logOut}
          variant="ghost"
          size="default"
          leftIcon="logout"
          alignment='left'
          className="text-sm"
        >
          Sign Out
        </CustomButton>
      </div>
    </Navbar>
  );
};

export default Sidebar;