import { useCallback, useEffect, useRef, useState } from "react";
import { ChatMode } from "src/enums/Chat";
import { DocumentContainerType } from "src/enums/Documents";
import { NotificationTypes } from "src/enums/Notifications";
import { ChatMessage } from "src/interfaces/Chat";
import { addSessions, appendMessageList, appendTracelessMessageList, clearTracelessMessages, removeSession, setQuestionMode, setSelectedSession, setSessionTitle, setSessions, setStopStream, stopStream, streamChatMessage, streamTracelessChatMessage, updateChatList, updateChatLoading, updateStreaming, updateSuggestedQuestionsList } from "src/redux/redux-store";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { ResponseKind } from "src/services/services/MainService";
import { chatService } from "src/services/services/chat/chat.api";
import { documentService } from "src/services/services/documents/documents.api";
import { isValidJSON } from "src/utils/helpers";
import { sendFailureNotification } from "src/utils/notifications";

export const useChat = () => {
    const dispatch = useAppDispatch()
    const chatLoading = useAppSelector((state) => state.chat.loading);
    let selectedSessionId = useAppSelector((state) => state.chat.selectedSession)
    const streaming = useAppSelector((state) => state.chat.streaming);
    const messages = useAppSelector((state) => state.chat).messages;
    const question_mode = useAppSelector((state) => state.chat.questionMode)
    const tracelessMessages = useAppSelector((state) => state.chat).tracelessMessages
    const stopStreamCheck = useAppSelector((state) => state.chat).stopStream
    const [inputMessage, setInputMessage] = useState('')
    const stopStreamCheckRef = useRef(stopStreamCheck);

    const getSessions = useCallback(async () => {
      const response  = await chatService.getAllSessions()
      console.log("Response for sessions = ", response)
      if(response.kind == ResponseKind.OK && response.data?.chat_sessions){
        dispatch(setSessions(response.data.chat_sessions))
        dispatch(setSelectedSession(response.data.chat_sessions[0]))
      }else{
        sendFailureNotification(NotificationTypes.chatSession, "Failed to retrieve chat sessions")
      }
    }, [])

    const newSession = useCallback(async () => {
      const session_title = `New Session`
      const response  = await chatService.createNewSession(session_title)
      if(response.kind == ResponseKind.OK && response.data){
        dispatch(addSessions(response.data))
        dispatch(setSelectedSession(response.data))
      }else{
        sendFailureNotification(NotificationTypes.chatSession, "Failed to create session")
      }
    }, [])

    const deleteSession = useCallback(async (session_id: string) => {
      const response  = await chatService.deleteSession(session_id)
      if(response.kind == ResponseKind.OK){
        if(selectedSessionId.id == session_id){
          dispatch(removeSession(session_id))
          newSession()
        }else{
          dispatch(removeSession(session_id))
        }
      }else{
        sendFailureNotification(NotificationTypes.chatSession, "Failed to delete sessions")
      }
    }, [selectedSessionId])
      
      const suggestQuestion = useCallback(async (check: boolean, session_id: string) => {
        try {   
          dispatch(setQuestionMode(false))
          setInputMessage("");

          if(check){
            dispatch(
              appendMessageList({
                role: "ai",
                content: "",
              })
            );
          }
            const response = await chatService.getSuggestedQuestion(check, session_id)
  
            if(response.kind == ResponseKind.OK && response.data){
  
              let stream = response.data.ai_response
  
                if(check){
                  dispatch(streamChatMessage(stream));
                }
              }
        } catch (e) {
        } finally {
          dispatch(updateStreaming(false));
        }
      }, [selectedSessionId]);    

    const updateSessionTitle = (title: string, id: string) => {
      dispatch(setSessionTitle({title: title, id: id}))
    }

    const triggerMessageForFileUpload = (message: string, file: File) => {
      let url = URL.createObjectURL(file)
      let JsonMessage
      if (file.type == 'application/pdf'){
        JsonMessage = {
          file_name: file.name
        }
      }else {
        JsonMessage = {
          image_url: url
        }
      }


      dispatch(updateStreaming(true))
      setInputMessage("");

      dispatch(
          appendMessageList({
            role: "human",
              content: JSON.stringify(JsonMessage),
            })
        );
      
      dispatch(
        appendMessageList({
          role: "human",
            content: message,
          })
      );
      
      dispatch(
        appendMessageList({
          role: "ai",
          content: "",
        })
      );
    } 

    const sendMessage = async (message: string, file: boolean) => {
        try {
          if(!file){
            dispatch(updateStreaming(true))
            const trimmedMessage = message.trim();
            
              setInputMessage("");
              if ((!trimmedMessage || trimmedMessage === '\n')) {
                return;
              }
              
            dispatch(
              appendMessageList({
                role: "human",
                  content: message,
                })
            );
            
            dispatch(
              appendMessageList({
                role: "ai",
                content: "",
              })
            );
          }

          const response = await chatService.sendMessage(message, file, selectedSessionId.id)

          if(response.kind == ResponseKind.OK && response.data){

            let stream = response.data.ai_response

            if(isValidJSON(stream)){
              dispatch(setQuestionMode(true))
            }
            
            for (let i = 0; i < stream.length; i++) {
              dispatch(streamChatMessage(stream[i]));
            }
     
       } } catch (e) {
        } finally {
          dispatch(updateStreaming(false));

          // if(messages.length == 1){
          //   const res = await chatService.updateSessionTitle(message, selectedSessionId.id)

          //   if(res.kind == ResponseKind.OK){
          //     updateSessionTitle(message, selectedSessionId.id)
          //   }
          // }

          // if(file){
          //   getMessages(selectedSessionId.id)
          // }
          // getSuggestedQuestions()
        }
      };

      const getMessages = async (sessionId: string) => {
        try {
          console.log("Called get messages ");
          
          dispatch(updateChatLoading(true));
          const response = await chatService.getAllMessages(selectedSessionId.id);
  
          if (response.kind == ResponseKind.OK) {
            const messages = response?.data?.messages;
    
            const tempMessages: ChatMessage[] = []
    
            if (messages != undefined) {
              dispatch(updateChatList([...tempMessages, ...messages]));
            }
            // getSuggestedQuestions()
          }
        } catch (e) {
        } finally {
          dispatch(updateChatLoading(false));
        }
      };

      return{
        getMessages,
        sendMessage,
        chatLoading,
        getSessions,
        newSession,
        triggerMessageForFileUpload,
        deleteSession,
        messages,
        suggestQuestion,
        tracelessMessages,
        streaming,
        question_mode,
        inputMessage,
        setInputMessage
      }
}


            // dispatch(streamChatMessage(response.data.ai_response))

            // }
            // let printstring = ''
            // let chunk = '';
            // let decoder = new TextDecoder()
            // const throttleRate = 100; // Throttle tokens to print per 100 milliseconds
            
          //   while (true) {
          //     if (stopStreamCheckRef.current) break;
          //     const { done, value } = await response.read();
          //     if (done) break;
          //     chunk = decoder.decode(value, {stream: true});
              
          //     // Throttle the tokens display (commented because of Azure OpenAI)
          //     // await new Promise(resolve => setTimeout(resolve, throttleRate));
              
          //     if(traceless){
          //       dispatch(streamTracelessChatMessage(chunk));
          //     }else{
          //       dispatch(streamChatMessage(chunk));
          //     }
              
          //   //     chunk = '';
          //   // }
          //   dispatch(stopStream(false))
          // }