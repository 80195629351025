import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from 'src/components/Layout/AuthLayout';
import { VisualSideLayout } from 'src/components/Layout/VisualsLayout';
import { EnterEmail } from 'src/components/Forms/ForgotPassword/EnterEmail';
import { VerifyCode } from 'src/components/Forms/ForgotPassword/VerifyCode';
import { SetNewPassword } from 'src/components/Forms/ForgotPassword/SetNewPassword';
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { ErrorKind } from "src/services/services/MainService/apiProblem";
import { authRoutes } from 'src/navigators/routes/auth';

enum ForgotPasswordStep {
  EMAIL,
  CODE,
  NEW_PASSWORD
}

const ForgotPassword: React.FC = () => {
  const [step, setStep] = useState(ForgotPasswordStep.EMAIL);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();


  const navigateToSignIn = useCallback(() => {
    navigate(authRoutes.LOGIN)
  }, [])

  const handleEmailSubmit = async (submittedEmail: string) => {
    setLoading(true);
    setError('');
    setEmail(submittedEmail)
    setStep(ForgotPasswordStep.CODE)
    setLoading(false)
    // try {
    //   const response = await authService.sendPasswordResetCode(submittedEmail);
    //   if (response.kind === ResponseKind.OK) {
    //     setEmail(submittedEmail);
    //     setStep(ForgotPasswordStep.CODE);
    //   } else {
    //     setError('Failed to send reset code. Please try again.');
    //   }
    // } catch (e) {
    //   setError('An unexpected error occurred. Please try again.');
    // } finally {
    //   setLoading(false);
    // }
  };

  const handleCodeSubmit = async (code: string) => {
    setLoading(true);
    setError('');
    setStep(ForgotPasswordStep.NEW_PASSWORD)
    setLoading(false)
    // try {
    //   const response = await authService.verifyPasswordResetCode(email, code);
    //   if (response.kind === ResponseKind.OK) {
    //     setStep(ForgotPasswordStep.NEW_PASSWORD);
    //   } else {
    //     setError('Invalid code. Please try again.');
    //   }
    // } catch (e) {
    //   setError('An unexpected error occurred. Please try again.');
    // } finally {
    //   setLoading(false);
    // }
  };

  const handleNewPasswordSubmit = async (newPassword: string) => {
    setLoading(true);
    setError('');
    navigate(authRoutes.LOGIN)
    setLoading(false)
    // try {
    //   const response = await authService.resetPassword(email, newPassword);
    //   if (response.kind === ResponseKind.OK) {
    //     navigate('/login', { state: { message: 'Password reset successful. Please log in with your new password.' } });
    //   } else {
    //     setError('Failed to reset password. Please try again.');
    //   }
    // } catch (e) {
    //   setError('An unexpected error occurred. Please try again.');
    // } finally {
    //   setLoading(false);
    // }
  };

  const handleResendCode = () => {
    handleEmailSubmit(email);
  };

  const renderStepComponent = () => {
    switch (step) {
      case ForgotPasswordStep.EMAIL:
        return <EnterEmail onSubmit={handleEmailSubmit} loading={loading} error={error} />;
      case ForgotPasswordStep.CODE:
        return <VerifyCode onSubmit={handleCodeSubmit} onResend={handleResendCode} loading={loading} error={error} email={email} />;
      case ForgotPasswordStep.NEW_PASSWORD:
        return <SetNewPassword onSubmit={handleNewPasswordSubmit} loading={loading} error={error} />;
    }
  };

  return (
    <AuthLayout
      visualComponent={<VisualSideLayout />}
      formComponent={
        <div className="w-full max-w-md">
          {renderStepComponent()}
          <div className="mt-8 text-center">
            <a onClick={navigateToSignIn} className="text-md">← Back to Sign In page</a>
          </div>
        </div>
      }
    />
  );
};

export default ForgotPassword;