import { Card, Image, Text } from "@mantine/core"
import Icon from "src/components/common/Icons/Icons/Icon"

export const EmptyChatComponent = () => {
    return(<div className="flex justify-center h-[100%] items-center">
    <div className="flex flex-col items-center justify-center">
    <Image src={'/images/temp-logo.png'} mah={80}  maw={80} className="self-center"/>
    <Text className="text-primary font-bold text-xxl">Hi Tania!</Text>
    <Text className=" font-bold text-lg">How can I help you today?</Text>
    <div className="flex flex-row gap-4 my-8">
    <Card withBorder className=" flex flex-col gap-2 items-center ">
    <Icon name="question" color="#00416A" size='2rem' />
    <Text className=" font-bold text-lg">Ask me a question</Text>
    </Card>
    <Card withBorder className=" flex flex-col gap-2 items-center ">
    <Icon name="message" color="#00416A" size='2rem' />
    <Text className=" font-bold text-lg">Answer my question</Text>
    </Card>
    </div>
    </div>
    </div>)
}