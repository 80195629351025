import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
  BackgroundImage,
  LoadingOverlay,
  Text,
} from "@mantine/core";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { UserRole } from "src/enums/User";
import { Navigate, useNavigate } from "react-router-dom";
import { getRole } from "src/utils/storage";
import {
  handleUpdateAppSettings,
  handleUserUpdate,
} from "src/redux/redux-store";
import { ChatStyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { Notifications } from "@mantine/notifications";
import useResponsive from "src/hooks/useResponsive";
import DynamicLayout from "src/components/Layout/DynamicLayout";
import ParentChildViewSidebar from "src/components/Sidebars/Parent/ParentSidebarChildView";
import useChildrenData from "src/hooks/useChildrenData";
import ReadOnlyChat from "src/components/ReadOnlyChat";
import { FlaggedData } from "src/interfaces/Chat";

const ChildView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(state => state.user)
  const { backgroundColor, backgroundColorExterior, isMobile, isDesktop } = useResponsive()
  const { childMessages, setSelectedChild, children, selectedChild, flagMessage, childSessions, getChildMessages, onSelectSession, selectedSession } = useChildrenData()

  const checkRole = async () => {
    const role = await getRole();

    if (role == UserRole.admin) {
      return navigate("/admin/", { replace: true });
    }
  };

  useEffect(() => {
    checkRole();
    getAllData();
  }, [])

  useEffect(() => {
    if (selectedSession) {
      getChildMessages(selectedChild, selectedSession)
    }
  }, [selectedSession])

  const checkFreeTrial = () => {
    // if (user.trial_expired && !user.paid) {
    //   navigate(userRoutes.PAYMENT)
    // }
  }

  const onSelectChild = (username: string) => {
    setSelectedChild(username)
  }


  const getAllData = useCallback(() => {
    dispatch(handleUserUpdate());
    dispatch(handleUpdateAppSettings());
    checkFreeTrial();
    // getAzureToken()
  }, [dispatch]);


  let heightStyle;
  if (!isDesktop) {
    // Is tablet or mobile (Max width 1024px)
    heightStyle = 'calc((var(--vh, 1vh) * 100) - 40px)';
  } else {
    // Desktop (Min width 1024px)
    heightStyle = 'calc((var(--vh, 1vh) * 100) - 70px)';
  }

  const header = (<div><Text>{selectedChild ? selectedChild : "Child View"}</Text></div>)

  return (
    <DynamicLayout
      heading={header}
      SidebarComponent={(props) => (
        <ParentChildViewSidebar
          children={children}
          childSessions={childSessions}
          selectedSession={selectedSession}
          onSelectSession={onSelectSession}
          onSelectChild={onSelectChild}
          selectedChild={selectedChild}
          {...props}
        />
      )}
    >
      <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
        <ChatStyledContainerInset theme={{ backgroundColor: backgroundColor }}>
          <BackgroundImage src="/images/doodles.png">
            <Notifications
              position={isMobile ? "top-center" : "bottom-right"}
              limit={isMobile ? 1 : 5}
            />
            <Notifications
              position={isMobile ? "top-center" : "bottom-right"}
              limit={isMobile ? 1 : 5}
            />
            {/* <ErrorMessage error={error ? error : ""} handleClose={dismissError} />  */}
            <LoadingOverlay visible={false} />
            {selectedChild ? childMessages.length > 0 ? <div
              style={{ height: heightStyle }}
              className={`flex flex-col lg:w-[800px] mx-auto overflow-hidden relative`}>
              <ReadOnlyChat childUsername={selectedChild} onFlagMessage={(flaggedData: FlaggedData) => flagMessage(flaggedData)} messages={childMessages} />
            </div> : <div className=" flex items-center justify-center w-full" style={{ height: heightStyle }}><Text>No chat history of your child found</Text></div> : <div className=" flex items-center justify-center w-full" style={{ height: heightStyle }}><Text>Please select a child from the sidebar</Text></div>}
          </BackgroundImage>
        </ChatStyledContainerInset>
      </StyledContainer>
    </DynamicLayout>
  );
};

export default ChildView;
