import React from 'react';
import { useForm, yupResolver } from '@mantine/form';
import { Button } from '@mantine/core';
import * as Yup from 'yup';
import InputField from '../common/Inputs/InputField';

interface PasswordChangeFormData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface PasswordChangeFormProps {
  onSubmit: (data: PasswordChangeFormData) => void;
  onCancel: () => void;
}

const passwordChangeSchema = Yup.object().shape({
    oldPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must have at least one special character")
    .matches(/\d/, "Password must have at least one number"),
    newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must have at least one special character")
    .matches(/\d/, "Password must have at least one number"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords must match')
      .required('Please confirm your new password'),
});

export const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ onSubmit, onCancel }) => {
  const form = useForm<PasswordChangeFormData>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validate: yupResolver(passwordChangeSchema),
    validateInputOnBlur: true,
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} className="space-y-4">
      <InputField
        type="password"
        placeholder="Current Password"
        {...form.getInputProps('oldPassword')}
      />
      <InputField
        type="password"
        placeholder="New Password"
        {...form.getInputProps('newPassword')}
      />
      <InputField
        type="password"
        placeholder="Confirm New Password"
        {...form.getInputProps('confirmPassword')}
      />
      <div className="flex justify-end space-x-2 mt-4">
        <Button variant="outline" onClick={onCancel} type="button">Cancel</Button>
        <Button type="submit">Save Changes</Button>
      </div>
    </form>
  );
};

export default PasswordChangeForm;