import React, { ReactNode } from 'react';
import { HeaderContainer } from '../common/Containers/HeaderContainer';
import { ActionIcon } from '@mantine/core';
import Icon from '../common/Icons/Icons/Icon';

interface ParentHeaderProps {
  renderHeading: ReactNode;
  sideBarOpened: boolean;
  openSideBar: () => void;
  isMobile: boolean;
}

const ParentHeader = ({ renderHeading, sideBarOpened, openSideBar, isMobile }: ParentHeaderProps) => {
  return (
    <header className="h-10 md:h-[70px]">
      <HeaderContainer>
        <div className='w-full flex flex-row items-center justify-between md:justify-start'>
          <div className='flex-none'>
            {!sideBarOpened && (
              <ActionIcon
                onClick={openSideBar}
                className="text-gray-600 hover:text-gray-800"
              >
                <Icon name="threeDots" />
              </ActionIcon>
            )}
          </div>
          <div className='flex-grow flex justify-center lg:justify-start'>
            <div className='lg:ml-4'>
              {renderHeading}
            </div>
          </div>
          {/* This empty div helps balance the layout on mobile */}
          <div className='flex-none lg:hidden w-10'></div>
        </div>
      </HeaderContainer>
    </header>
  );
};

export default ParentHeader;