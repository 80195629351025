import { Avatar, Card, Image, Text, useMantineTheme } from "@mantine/core";
import { isValidJSON } from "src/utils/helpers";
import { DocumentTile } from "./common/Tiles/DocumentTile";
import { useState } from "react";
import Icon from "./common/Icons/Icons/Icon";

const UserMessage = (props: { message: string, traceless?: boolean }) => {
  const { traceless } = props
  const theme = useMantineTheme()
  const [imageError, setImageError] = useState(false)

  if (isValidJSON(props.message)) {
    const formattedMessage = JSON.parse(props.message)


    if (formattedMessage.image_url) {    //Image Message
      return (
        <div className="flex flex-col items-end justify-start pt-0 mq800:max-w-full">
          <div className={`flex flex-row gap-4 items-center justify-end`}>
          {!imageError ? <div className="rounded-lg border w-1/2 bg-primary p-1"><Image
            radius={10}
            onError={() => setImageError(true)}
            src={formattedMessage.image_url}
          />  </div> : <Card className=" flex justify-center gap-2 items-center rounded-lg bg-primary py-3 px-8" withBorder >
            <Icon size="15" color="white" name="image" /><Text className="text-white font-semibold">{formattedMessage.file_name}</Text>
          </Card>}
          <Image src={'/images/user-avatar.png'} mah={40}  maw={40}/>
          </div>
        </div>
      )
    } else {
      return (    //Document Message
      <div className=" flex flex-col items-end justify-start pt-0 mq800:max-w-full">
          <div className={`flex flex-row gap-4 items-center`}>
         <Card className=" flex justify-center bg-primary py-3 px-8 items-center rounded-lg gap-2" withBorder >
            <Icon size="15" color="white" name="file" /><Text  className="text-white font-semibold">{formattedMessage.file_name}</Text>
          </Card>
          <Image src={'/images/user-avatar.png'} mah={40}  maw={40}/>
          </div>
        </div>
      )
    }

  }

  return (
    <div className=" flex flex-col items-end justify-start mq800:max-w-full">
      <div className={`flex flex-row gap-4 items-center`}>
          <div className={`relative rounded-lg bg-primary py-3 px-8 text-md leading-[22px] text-left font-semibold text-white inline-block max-w-[400px]`}>
            {props.message}
          </div>
          <Image src={'/images/user-avatar.png'} mah={40}  maw={40}/>
      </div>
    </div>
  );
};

export default UserMessage;