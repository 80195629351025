import React, { useState, useEffect } from 'react';
import { Button, Card, Text, Group, List, ThemeIcon, Loader, Badge } from "@mantine/core";
import { useAppSelector } from "src/redux/redux-store/hooks";
import { PriceItem } from "src/interfaces/Payments";
import { ResponseKind } from "src/services/services/MainService";
import { paymentService } from "src/services/services/payment/payment.api";
import Icon from '../common/Icons/Icons/Icon';
import { useNavigate } from 'react-router-dom';
import { parentRoutes } from 'src/navigators/routes/ParentRoutes';
import { PlanCard } from '../Cards/PlanCard';
import { usePayments } from 'src/hooks/usePayment';


export const ManageSubscription: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const {isPaidUser, prices, userPlan, loading, navigateToPortal  } = usePayments()

  const onSubscribe = () => {
    window.location.href = parentRoutes.PAYMENT;
  };

  const onChangePlan = () => {
     window.location.href = parentRoutes.PAYMENT;
  };

  const handleCancellation = async () => {
    const url = await navigateToPortal();
    if (url) {
      window.location.href = url;
    }
  }

  const getSubscriptionDetails = () => {
    if (!isPaidUser || !userPlan) return null;
    return (
      <div className="mb-4">
        <Text size="sm" weight={500}>Current Subscription:</Text>
        <Text size="sm">Plan: {userPlan.product.name}</Text>
        <Text size="sm">Billing: {userPlan.recurring.interval_count === 1 ? 'Monthly' :
          userPlan.recurring.interval_count === 6 ? '6 Months' : 'Yearly'}</Text>
        <Text size="sm">Renewal: Every {userPlan.recurring.interval_count} month(s)</Text>
      </div>
    );
  };

  if (loading) {
    return <Loader size="xl" className="mx-auto mt-8" />;
  }

  return (
    <div className="flex flex-col h-full mt-5 overflow-hidden">
      <Text size="xl" weight={500} className="mb-4">Manage Subscription</Text>
      <div className="flex-grow overflow-hidden">
        <div className="h-full overflow-y-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 pb-8">
            <PlanCard
              title="Free"
              isCurrentPlan={user.plan === "Free"}
              price={0}
              description="Start with our free plan"
              features={[
                'Basic features',
                'Limited usage',
                'Community support'
              ]}
              buttonText={isPaidUser ? "Switch to Free" : "Upgrade to Pro"}
              buttonVariant="outline"
              buttonColor={isPaidUser ? "gray" : "blue"}
              onButtonClick={isPaidUser ? handleCancellation : onSubscribe}
            />
            <PlanCard
              title="Pro"
              isCurrentPlan={isPaidUser}
              price={userPlan ? userPlan.unit_amount / 100 : 9.99}
              description={isPaidUser ? "Your current Pro plan" : "Unlock all features with our Pro plan"}
              features={[
                'All features included',
                'Unlimited usage',
                'Priority support'
              ]}
              buttonText={isPaidUser ? "Change Plan" : "Subscribe"}    //REITERATE
              buttonVariant="filled"
              buttonColor="blue"
              onButtonClick={isPaidUser ? onChangePlan : onSubscribe}
              additionalButtons={
                isPaidUser && (
                  <Button variant="outline" color="red" fullWidth onClick={handleCancellation} mt="md">
                    Cancel Plan
                  </Button>
                )
              }
              subscriptionDetails={getSubscriptionDetails()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSubscription
