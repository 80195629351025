import { useState, useCallback } from 'react';
import { useNotifications } from 'src/hooks/useNotifications';
import { documentService } from 'src/services/services/documents/documents.api';
import { PubSubNotificationTypes } from 'src/enums/Notifications';

export const useFileUpload = () => {
    const [uploadStatus, setUploadStatus] = useState<'idle' | 'uploading' | 'success' | 'error'>('idle');
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const client = useNotifications()?.client;

    const uploadFile = useCallback(async (file: File, sessionId: string): Promise<boolean> => {
        setUploadStatus('uploading');
        setUploadedFile(file);
        try {
          const res = await documentService.uploadDoc(file, sessionId);
          if (!res) {
            throw new Error("Error uploading file");
          }
          return new Promise((resolve) => {
            const handleServerMessage = (data: any) => {
              if (data.message.data) {
                const message = JSON.parse(data.message.data.toString());
                console.log("response from pub sub", message)
                if (message.event_type === PubSubNotificationTypes.fileUpload && message.file_name === file.name) {
                  if (message.status === "Completed") {
                    client?.off("server-message", handleServerMessage);
                    setUploadStatus('success');
                    resolve(true);
                  }
                }
              }
            };
            client?.on("server-message", handleServerMessage);
          });
        } catch (error) {
          console.error('Error uploading file:', error);
          setUploadStatus('error');
          return false;
        }
      }, [client]);

    return { uploadStatus, uploadedFile, uploadFile, setUploadedFile, client, setUploadStatus };
};