import { Badge, Button, Card, Text } from "@mantine/core";

interface ProPlanCardProps {
    title: string;
    price: number;
    description: string;
    onSubscribe: () => void;
    onCancel: () => void;
    isCurrentPlan: boolean;
  }
  
 export const ProPlanCard: React.FC<ProPlanCardProps> = ({ 
    title, 
    price, 
    description, 
    onSubscribe, 
    onCancel,
    isCurrentPlan 
  }) => (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <div className="flex justify-between items-start mb-2">
        <Text size="lg" weight={700}>{title}</Text>
        {isCurrentPlan && <Badge color="green">Current Plan</Badge>}
      </div>
      <Text size="sm" color="dimmed" className="mb-4">{description}</Text>
      <Text size="xl" weight={700} className="mb-4">
        ${price} <span className="text-sm font-normal">/month</span>
      </Text>
      <Button
        variant={isCurrentPlan ? "outline" : "filled"} 
        color={isCurrentPlan ? "red" : "blue"} 
        fullWidth 
        onClick={isCurrentPlan ? onCancel : onSubscribe}
      >
        {isCurrentPlan ? "Cancel Subscription" : "Subscribe"}
      </Button>
    </Card>
  );