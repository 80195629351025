// icons/IconRegistry.ts
import SearchIcon from '@mui/icons-material/Search';
import UserIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import FourDots from "../../../../../public/staticwebapp.config.json"
import { PaletteOutlined, BuildCircleOutlined, CopyAllOutlined, DarkMode, DarkModeOutlined, Delete, DeleteOutline, DonutLargeOutlined, FileUploadOutlined, FilterCenterFocusOutlined, HomeOutlined, HomeRounded, InfoOutlined, LightMode, LightModeOutlined, LogoutOutlined, MessageOutlined, MoodOutlined, MoreHorizOutlined, SaveOutlined, StopCircle, TuneOutlined, WorkspacesRounded, Psychology, SettingsOutlined, CopyAllRounded, ContentCopyRounded, CropSquareSharp, CancelOutlined, Circle, CircleOutlined, CheckCircle, ArrowBack, RefreshOutlined, BrushOutlined, EditNote, PsychologyOutlined, AttachFileOutlined, InsertDriveFile, AttachFile, Close, Image, Check, QuestionMark, QuestionAnswer, FlagOutlined, ChatBubble, ChatBubbleOutline, ChatBubbleOutlineRounded, ChatBubbleRounded, ListAltOutlined, HistoryEduOutlined, HistoryOutlined, PersonAddAlt1Outlined, ImageOutlined, PictureAsPdfOutlined } from '@mui/icons-material';
import { GoogleIcon } from '../GoogleIcon';
import { EyeIcon, EyeSlashIcon, PencilIcon } from '@heroicons/react/24/solid';
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline';

// Exporting Material UI icons as a centralized registry
export const iconRegistry = {
  search: SearchIcon,
  user: UserIcon,
  eye: EyeIcon,
  eyeSlash: EyeSlashIcon,
  list: ListAltOutlined,
  addUser: PersonAddAlt1Outlined,
  image: ImageOutlined,
  doc: PictureAsPdfOutlined,
  recent: HistoryOutlined,
  chat: ChatBubbleOvalLeftIcon,
  home: HomeRounded,
  clear: DeleteOutline,
  darkMode: DarkModeOutlined,
  lightMode: LightModeOutlined,
  logout: LogoutOutlined,
  fourDots: FilterCenterFocusOutlined,
  info: InfoOutlined,
  upload: FileUploadOutlined,
  edit: EditNote,
  flag: FlagOutlined,
  cross: Close,
  google: GoogleIcon,
  settings: TuneOutlined,
  copy: ContentCopyRounded,
  message: MessageOutlined,
  threeDots: MoreHorizOutlined,
  check: Check,
  knowledgebase: CopyAllOutlined,
  save: SaveOutlined,
  delete: CancelOutlined,
  question: QuestionMark,
  qa: QuestionAnswer,
  pen: EditNote,
  brain: Psychology,
  attach: AttachFile,
  file: InsertDriveFile,
  trash: DeleteOutline,
  refresh: RefreshOutlined,
  bullet: Circle,
  success: CheckCircle,
  back: ArrowBack,
  stop: StopCircle,
  paletteOutlined: PaletteOutlined,
  settings2: SettingsOutlined
};

// TypeScript type for the registry keys
export type IconName = keyof typeof iconRegistry;
