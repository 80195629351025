
  import {  useAppSelector } from "src/redux/redux-store/hooks";
  import { useState } from "react";
  import { useNavigate } from "react-router-dom";
  import useViewportHeight from "src/hooks/useViewPortHeight";
import { ChildrenData } from "src/interfaces/Children";
import ChildSelect from "src/components/common/Dropdown/SelectChild";
import { SidebarBottomSection } from "src/components/Sidebar/BottomSection";
import { ParentChildViewSection } from "src/components/Sidebar/BottomSection/ParentChildView";
import { ChatSession } from "src/interfaces/Chat";
import { BaseSidebar } from "./BaseSidebar";
  
  interface SideBarProps {
    opened: boolean;
    selectedSession: string
    childSessions: ChatSession[]
    onSelectSession: (id: string) => void
    children: ChildrenData[]
    selectedChild: string
    onSelectChild: (username: string) => void
  }
  
  
 const ParentChildViewSidebar = (props: SideBarProps) => {
    const {children, onSelectChild, selectedChild, childSessions, selectedSession, onSelectSession} = props
    const user = useAppSelector((state) => state.user)
    const trial_days = user.trial_days;
    const [paymentUrl, setPaymentUrl] = useState("");
    const navigate = useNavigate();
    useViewportHeight()

  
    return (
      <BaseSidebar fullName={user.name} screenName="Child View" >
       <ChildSelect onSelectChild={onSelectChild} children={children} value={selectedChild} />
      <ParentChildViewSection selectSession={onSelectSession} selectedSession={selectedSession} chats={childSessions} /> 
      </BaseSidebar>
      )
  };
  
  export default ParentChildViewSidebar;
  