import React from 'react';
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { Text } from "@mantine/core";
import InputField from "src/components/common/Inputs/InputField";
import { CustomButton } from "src/components/common/Buttons/CustomButton";
import { AuthHeader } from 'src/components/Cards/AuthHeaderCard';

const schema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must have at least one uppercase letter")
      .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must have at least one special character")
      .matches(/\d/, "Password must have at least one number"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm password is required'),
  });
  
type PasswordFormValues = Yup.InferType<typeof schema>;

interface SetNewPasswordProps {
  onSubmit: (password: string) => void;
  loading: boolean;
  error?: string;
}

export const SetNewPassword: React.FC<SetNewPasswordProps> = ({ onSubmit, loading, error }) => {
  const form = useForm<PasswordFormValues>({
    initialValues: { password: "", confirmPassword: "" },
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values.password))}>
      <div className='space-y-6'>
        <AuthHeader title='Set new password' subtitle='Must be at least 8 characters.' />
    
      <InputField
        label="New Password"
        type="password"
        name="password"
        placeholder="Enter new password"
        showPasswordToggle
        error={form.errors.password}
        {...form.getInputProps('password')}
      />
      <InputField
        label="Confirm Password"
        type="password"
        name="confirmPassword"
        placeholder="Confirm new password"
        showPasswordToggle
        error={form.errors.confirmPassword}
        {...form.getInputProps('confirmPassword')}
      />
      <CustomButton type="submit" loading={loading} disabled={!form.isValid()} className="mt-4">
        Reset Password
      </CustomButton>
      {error && <Text color="red" className="mt-2">{error}</Text>}
      </div>
    </form>
  );
};