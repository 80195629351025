import useResponsive from "src/hooks/useResponsive"
import { NavBarContainer, NavBarParentContainer } from "../StyledComponents/NavBarContainer"
import { ReactNode } from "react";

interface ContainerProps {
    children: ReactNode;
  }

export const HeaderContainer: React.FC<ContainerProps> = ({children }) => {
    const {backgroundColor, backgroundColorExterior} = useResponsive()
    return(
        <NavBarParentContainer
        theme={{ backgroundColor: backgroundColorExterior }}
      >
       
        <NavBarContainer theme={{ backgroundColor: backgroundColor }}>
        {children}
            </NavBarContainer></NavBarParentContainer>
    )
}