import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS } from "src/utils/styles";
import { Notifications } from "@mantine/notifications";
import useResponsive from "src/hooks/useResponsive";
import DynamicLayout from "src/components/Layout/DynamicLayout";
import RegisterChildForm from "src/components/Forms/RegisterChild";
import ParentRegisterChildSidebar from "src/components/Sidebars/Parent/ParentSidebarRegisterChild";
import useChildrenData from "src/hooks/useChildrenData";
import { Text } from "@mantine/core";
import ChildList from "src/components/common/Lists/ChildList";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { parentRoutes } from "src/navigators/routes/ParentRoutes";

const LearnerPortalScreen = () => {
  const navigate = useNavigate()  
  const { backgroundColor, backgroundColorExterior, isMobile, isDesktop } = useResponsive()
  const {children, createChild, deleteChild} = useChildrenData()


  const onAddChild = useCallback(() => {
   window.location.href = parentRoutes.REGISTER_CHILD
  }, [])

  const header = (<div className="flex flex-col">
      <Text size={"lg"} fw={"bold"}>Learner Portal</Text>
    </div>)

  return (
    <DynamicLayout
      heading = {header}
      SidebarComponent={(props) => (
        <ParentRegisterChildSidebar deleteChild={deleteChild} children={children} {...props}/>
      )}
    >
    <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
      <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
        <Notifications
          position={isMobile ? "top-center" : "bottom-right"}
          limit={isMobile ? 1 : 5}
        />

        <ChildList onAddChild={onAddChild} children={children} onDeleteChild={deleteChild} />
      </StyledContainerInset>
    </StyledContainer>
    </DynamicLayout>
  );
};

export default LearnerPortalScreen;
