import styled from "@emotion/styled";

interface ThemeProps {
  theme: {
    backgroundColor: string;
  };
}

export const StyledContainerInset = styled.div<ThemeProps>`
  background-color: ${(props) => props.theme.backgroundColor || 'white'};
  height: 99.20%; // Takes 95% of the parent's height
  width: 99%; // Takes 95% of the parent's width
  border-radius: 0.45rem 0.45rem 0.45rem 0.45rem;   // Consistent with the outer's border-radius in larger screens
  margin: 0;
  padding: 0;  

  @media (min-width: 992px) {
    height: 99%; // Takes 95% of the parent's height
    width: 99%; // Takes 95% of the parent's width
    display: flex;
    align-items: flex-end; 
  }

  @media (max-width: 992px) {
    height: 100%;
    width: 100%;
    border-radius: 0 0 0 0;
    // Additional styling for larger screens can be placed here if needed
  }
`;

export const ChatStyledContainerInset = styled.div<ThemeProps>`
  background-color: ${(props) => props.theme.backgroundColor || 'white'};
  border-radius: 0.45rem 0.45rem 0.45rem 0.45rem;   // Consistent with the outer's border-radius in larger screens
  margin: 0;
  padding: 0; 
  overflow: hidden;
  border-width: 1px;
  
  @media (min-width: 992px) {
    height: 99%; // Takes 95% of the parent's height
    width: 99%; // Takes 95% of the parent's width
    display: flex;
    align-items: flex-end; 
  }

  @media (max-width: 992px) {
    height: 100%;
    width: 100%;
    border-radius: 0 0 0 0;
    // Additional styling for larger screens can be placed here if needed
  }
`;