import React from 'react';
import { Select, Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import InputField from "src/components/common/Inputs/InputField";
import { CustomButton } from '../common/Buttons/CustomButton';
import ImageInputField from '../common/Inputs/ImageInputField';
import SelectField from '../common/Inputs/SelectField';
import { ChildLevel } from 'src/enums/Child';

const currentYear = new Date().getFullYear();

const schema = Yup.object().shape({
  image: Yup.mixed()
    .nullable()
    .test('fileSize', 'The file is too large', (value) => {
      if (!value || !(value instanceof File)) return true;
      return value.size <= 5000000; // 5MB max size
    })
    .test('fileType', 'Unsupported file format', (value) => {
      if (!value || !(value instanceof File)) return true;
      return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
    }),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  birthYear: Yup.number()
    .required("Birth year is required")
    .min(currentYear - 100, "Invalid birth year")
    .max(currentYear, "Invalid birth year"),
  grade: Yup.string()
    .required("Grade is required"),
  school: Yup.string().required("School is required"),
  username: Yup.string()
    .required("Username is required")
    .matches(/^\S*$/, "Username should not contain spaces"),
  password: Yup.string()
    .required("Password is required")
    .matches(/^(?=.*[A-Z])(?=.*\d).{8,}$/, "Use at least one capital letter and a number in your password"),
});

export interface RegisterFormData {
  image: File | null;
  firstName: string;
  lastName: string;
  birthYear: number;
  grade: ChildLevel;
  school: string;
  username: string;
  password: string;
}

interface RegisterChildFormProps {
  onSubmit: (data: RegisterFormData) => void;
  schools: { value: string; label: string }[];
}

const RegisterChildForm: React.FC<RegisterChildFormProps> = ({ onSubmit, schools }) => {
  const form = useForm<RegisterFormData>({
    initialValues: {
      image: null,
      firstName: '',
      lastName: '',
      birthYear: new Date().getFullYear(),
      grade: ChildLevel.primary_3,
      school: '',
      username: '',
      password: '',
    },
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = (values: RegisterFormData) => {
    onSubmit(values);
    form.reset();
  };

  return (
    <div className='w-full xl:w-[80%] 2xl:w-[40%] p-4' >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div className='space-y-6'>
        <ImageInputField
            onChange={(file) => form.setFieldValue('image', file)}
            error={form.errors.image as string | undefined}
          />
          <InputField
            label="First Name"
            placeholder="Enter first name"
            {...form.getInputProps('firstName')}
          />
          <InputField
            label="Last Name"
            placeholder="Enter last name"
            {...form.getInputProps('lastName')}
          />
          <div className="grid grid-cols-2 gap-4">
            <SelectField
              label="Birth Year"
              placeholder="Select year of birth"
              data={Array.from({ length: 100 }, (_, i) => ({
                value: (currentYear - i).toString(),
                label: (currentYear - i).toString()
              }))}
              {...form.getInputProps('birthYear')}
            />
            <SelectField
              label="Grade"
              placeholder="Select grade"
              data={[{value: ChildLevel.primary_3, label: ChildLevel.primary_3}, 
                {value: ChildLevel.primary_4, label: ChildLevel.primary_4}, 
                {value: ChildLevel.primary_5, label: ChildLevel.primary_5}, 
                {value: ChildLevel.primary_6, label: ChildLevel.primary_6}, 
              ]}
              {...form.getInputProps('grade')}
            />
          </div>
          <SelectField
            label="School"
            placeholder="Select school"
            data={schools}
            {...form.getInputProps('school')}
          />
          <InputField
            label="Username"
            placeholder="Enter username"
            {...form.getInputProps('username')}
          />
          <Text size="xs" color="gray" mt={-8} mb={8}>Please choose a User ID without any space.</Text>
          <InputField
            label="Password"
            type="password"
            placeholder="Enter password"
            {...form.getInputProps('password')}
            showPasswordToggle
          />
          <Text size="xs" color="gray" mt={-8} mb={16}>Use at least one capital letter and a number in your password.</Text>
          <CustomButton type="submit" disabled={!form.isValid()}>
            Register
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default RegisterChildForm;