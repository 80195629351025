import {
  Card,
  Center,
  Container,
  Image,
  ScrollArea,
  Text,
  createStyles,
} from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import BotMessage from "./BotMessage/BotMessage";
import UserMessage from "./UserMessage";
import { ChatMessage } from "src/interfaces/Chat";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useMediaQuery } from "@mantine/hooks";
import { optionButtons } from "./BotMessage/metadata";
import useResponsive from "src/hooks/useResponsive";
import useViewportHeight from "src/hooks/useViewPortHeight";
import Icon from "./common/Icons/Icons/Icon";
import { EmptyChatComponent } from "./Chat/EmptyChatComponent";

interface ChatArea {
  messages: ChatMessage[];
  streaming?: boolean;
}

export const ChatArea = (props: ChatArea) => {
  const { messages, streaming } = props;
  const viewport: any = useRef<HTMLDivElement>(null);
  const [isAtBottomButtonTrigger, setAtBottomButtonTrigger] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const {isMobile, isDesktop} = useResponsive()
  useViewportHeight()

  useEffect(() => {
   scrollToBottom()
  }, [showOptions])

  useEffect(() => {
    // Scroll to bottom on initial page load
    scrollToBottom()

    const handleScrollButtonTrigger = () => {
      if (!viewport.current) {
        return;
      }
      
      // Calculate the difference and check if it's within a threshold
      const bottomThreshold = isMobile ? 120 : 100; // pixels from the bottom
      const isBottom = viewport.current.scrollHeight - viewport.current.scrollTop <= viewport.current.clientHeight + bottomThreshold;
      setAtBottomButtonTrigger(isBottom);
    };
    
    const handleScroll = () => {
      if (!viewport.current) {
        return;
      }
      
      // Calculate the difference and check if it's within a threshold
      const bottomThreshold = isMobile ? 30 : 50;// pixels from the bottom
      const isBottom = viewport.current.scrollHeight - viewport.current.scrollTop <= viewport.current.clientHeight + bottomThreshold;
      setIsAtBottom(isBottom);
    };

    viewport.current?.addEventListener('scroll', handleScrollButtonTrigger);
    viewport.current?.addEventListener('scroll', handleScroll);

    return () => {
      viewport.current?.removeEventListener('scroll', handleScrollButtonTrigger);
      viewport.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
      if (streaming){
        if (isAtBottom){
          scrollToBottom();
        }
    }
  }, [messages, streaming]);

  const handleOpenOptions = useCallback(() => {
    setShowOptions((prev) => !prev);
  }, [])

  const closeOptions = useCallback(() => {
    setShowOptions(false);
  }, [])

  const scrollToBottom = () => {
    viewport.current.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: "smooth",
    });
  };



  return (
    <>
      <div
        ref={viewport}
        className={`flex-1 overflow-y-auto scrollbar-hide py-8 px-2 space-y-8`}
      >
          {messages.length > 0 ? messages?.map((item, index) => {
            let isLastMessage = index === messages.length - 1;
            if (item.role == "human") {
              return <UserMessage message={item.content} />;
            } else {
              return isLastMessage ? (<BotMessage
                  index={index}
                  showOptions={showOptions}
                  message={item.content}
                  isLatest={isLastMessage}
                  toggleOptions = {handleOpenOptions}
                  closeOptions={closeOptions}
                  streaming={streaming}
                />
              ): (<BotMessage
                index={index}
                message={item.content}
                isLatest={isLastMessage}
                streaming={streaming}
              />)
            }
          }): <EmptyChatComponent/>
            }
      </div>
      {!isAtBottomButtonTrigger && (
          <div className="absolute inset-x-0 bottom-32 flex justify-center">
          <ArrowDownwardIcon
          onClick={scrollToBottom}
          className="cursor-pointer z-40 p-1 rounded-full text-lg text-theme-color-4 bg-theme-color-2"
          style={{ fontSize: '2rem' }}/>
        </div>
      )}
          </>
  );
};
