import { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import { UserToken } from 'src/interfaces';
import { UserRole } from 'src/enums/User';
import { authService } from 'src/services/services/auth/auth.api';
import { getStorageToken, setStorageToken, clearStorageToken } from 'src/utils/auth.storage';
import { ResponseKind } from 'src/services/services/MainService';

interface AuthContextType {
  token: UserToken | null;
  login: (newToken: UserToken) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
  role: UserRole | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuthProvider = () => {
  const [token, setToken] = useState<UserToken | null>(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState<UserRole | null>(null);

  useEffect(() => {
    const initAuth = async () => {
      const storedToken = await getStorageToken();
      if (storedToken) {
        setToken(storedToken);
        setRole(storedToken.role);
      }
      setLoading(false);
    };

    initAuth();
  }, []);

  const login = async (newToken: UserToken) => {
    await setStorageToken(newToken);
    setToken(newToken);
    setRole(newToken.role);
  };

  const logout = async () => {
    try {
      const res = await authService.signOut();

      if(res.kind == ResponseKind.OK){
        await clearStorageToken();
        setToken(null);
        setRole(null);
      }else{
        throw "Error"
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return { token, login, logout, loading, role };
};

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};