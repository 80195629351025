import React from 'react';
import { Card, Text } from '@mantine/core';
import Icon from '../common/Icons/Icons/Icon';
import { ChildrenData } from 'src/interfaces/Children';

interface ChildCardProps {
  child: ChildrenData;
  onDelete: (name: string) => void;
  colorIndex: number;
}

const vibrantColors = [
  'bg-blue-500',
  'bg-orange-500',
  'bg-purple-500',
  'bg-green-500',
  'bg-yellow-500',
  'bg-red-500',
  'bg-indigo-500',
  'bg-pink-500',
];

const ChildCard = ({ child, onDelete, colorIndex }: ChildCardProps) => {
  const topColor = vibrantColors[colorIndex % vibrantColors.length];

  return (
    <Card withBorder p={0} className="flex flex-col w-full rounded-xl overflow-hidden shadow-sm">
      <div className={`${topColor} h-24 w-full`}></div>
      <div className="flex flex-col items-center px-4 pb-4 -mt-12">
        <div className="w-24 h-24 rounded-full bg-white flex items-center justify-center mb-3 border-4 border-white shadow-md">
            <Text className="text-3xl text-gray-700">{child.username[0].toUpperCase()}</Text>
        </div>
        <Text size="lg" weight={500} className="mb-1">{child.username}</Text>
        <Text size="sm" color="dimmed" className="mb-4">Grade {child.level}</Text>
        <button
          className="mt-auto w-full px-4 py-2 text-sm text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors flex items-center justify-center"
          onClick={() => onDelete(child.username)}
        >
          <Icon name="trash" className="mr-2" />
          Remove
        </button>
      </div>
    </Card>
  );
};

export default ChildCard;