import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface ScrollToBottomButtonProps {
  onClick: () => void;
}

const ScrollToBottomButton: React.FC<ScrollToBottomButtonProps> = ({ onClick }) => (
  <div className="absolute inset-x-0 bottom-32 flex justify-center">
    <ArrowDownwardIcon
      onClick={onClick}
      className="cursor-pointer z-40 p-1 rounded-full text-lg text-theme-color-4 bg-theme-color-2"
      style={{ fontSize: '2rem' }}
    />
  </div>
);

export default ScrollToBottomButton;