import { Text } from "@mantine/core"
import { profile } from "console"
import { useNavigate } from "react-router-dom"
import { CustomButton } from "src/components/common/Buttons/CustomButton"
import { IconName } from "src/components/common/Icons/Icons/iconRegistry"
import { useAuth } from "src/hooks/useAuth"
import { parentRoutes } from "src/navigators/routes/ParentRoutes"
import { authRoutes } from "src/navigators/routes/auth"
import { clearChatState, resetUser } from "src/redux/redux-store"
import { useAppDispatch } from "src/redux/redux-store/hooks"
import { ResponseKind } from "src/services/services/MainService"
import { authService } from "src/services/services/auth/auth.api"
import { clearToken } from "src/utils/storage"

interface BaseSidebar {
    fullName: string
    screenName: 'Home' | 'Learner Portal' | 'Child View' | 'Profile'
    children: React.ReactNode
}

export const BaseSidebar = (props: BaseSidebar) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {logout} = useAuth()
    const {fullName, screenName, children} = props

    const buttons: {name: string, icon: IconName, navigate: string} [] = [
        { name: 'Home', icon: 'home', navigate: parentRoutes.DASHBOARD},
        { name: 'Learner Portal', icon: 'list', navigate: parentRoutes.LEARNER_PORTAL},
        { name: 'Child View', icon: "recent", navigate:  parentRoutes.CHILD_VIEW},
    ];

    const logOut = async () => {
        const response = await authService.signOut();
    
        if (response.kind == ResponseKind.OK) {
          navigate(authRoutes.LOGIN, { replace: true });
          clearToken();
          dispatch(resetUser());
          dispatch(clearChatState());
          logout();
        }
    };

    const goToProfile = async () => {
         window.location.href = parentRoutes.PROFILE
    };


    return(
        <div className="flex flex-col h-screen text-gray-800 bg-white w-full p-4">
         <div className="flex-shrink-0">
        <div className="flex items-center space-x-3 mt-2 mb-5">
          <div className="w-12 h-12 bg-orange-500 rounded-full flex items-center justify-center text-white font-bold text-xl">
            {fullName.charAt(0).toUpperCase()}
          </div>
          <div>
            <Text className="font-bold">{fullName}</Text>
            <Text className="text-sm text-gray-600">Parent Portal</Text>
          </div>
        </div>
        </div>

        <div className=" space-y-4 mb-6 mt-4">
        {
            buttons.map((item) => (
                <CustomButton            
                leftIcon= {item.icon}
                alignment= 'left'
                className="font-normal"
                size= 'md'
                onClick={() => window.location.href = item.navigate}
                variant= {item.name == screenName ? "secondary" : 'ghost'}>
                {item.name}
                </CustomButton>))
        }
        </div>

        <div className="flex-grow overflow-auto">
        {children}
        </div>
        
        <div className="flex-shrink-0 mt-auto">
        <CustomButton
          onClick={goToProfile}
          variant={screenName == "Profile" ? "secondary" : "ghost"}
          size="default"
          leftIcon="user"
          alignment='left'
          className= "text-sm"
        >
          Profile
        </CustomButton>
  
        <CustomButton
          onClick={logOut}
          variant="ghost"
          size="default"
          leftIcon="logout"
          alignment='left'
          className= "text-sm text-red-500"
        >
          <Text className="text-red-500 font-semibold">Sign Out</Text>
        </CustomButton>
        </div>

      </div>
    )
}