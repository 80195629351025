export enum NotificationTypes{
    fileUpload = "File Upload",
    childCreation = "Child Creation",
    flagMessage = "Flagging Message",
    chatSession = "Chat Session",
    UserManagement = "User Management",
    resolveComplaint = "Complaint Center",
    fileLimit = "Documents limit",
    deleteRestricted = 'Delete Restricted',
    fileDeletion = "File Deletion",
    fileSize = "File Size"
}

export enum PubSubNotificationTypes{
    fileUpload = "file_upload",
    fileDeletion = "file_delete",
    appInfoUpdate = "app_info_updated"
}

