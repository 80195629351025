import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { NotificationTypes } from "src/enums/Notifications";
import { ChatMessage, ChatSession, FlaggedData } from "src/interfaces/Chat";
import { ChildCreationData, ChildrenData } from "src/interfaces/Children";
import { ResponseKind } from "src/services/services/MainService";
import { MessageList } from "src/services/services/chat/chat.api.types";
import { ParentApi, parentService } from "src/services/services/parent/parent.api";
import { sendFailureNotification, sendSuccessNotification } from "src/utils/notifications";

const useChildrenData = () => {
const [children, setChildren] = useState<ChildrenData[]>([])  
const [childMessages, setChildMessages] = useState<ChatMessage[]>([])  
const [childSessions, setChildSessions] = useState<ChatSession[]>([])
const [selectedSession, setSelectedSession] = useState<string>('')
const [chatLoading, setChatLoading] = useState<boolean>(false)
const [selectedChild, setSelectedChild] = useState<string>('')

    useEffect(() => {
        getChildren()
    }, [])

    useEffect(() => {
        if(selectedChild){
            getChildSessions(selectedChild)
        }else{
            setChildSessions([])
            setSelectedSession('')
            setChildMessages([])
        }
    }, [selectedChild])

const getChildren = async () => {
    const response = await parentService.getChildren()
    if(response.kind == ResponseKind.OK && response.data){
        setChildren(response.data.children)
    }
}

const createChild = async (data: ChildCreationData) => {
    const response = await parentService.createChild(data)

    if(response.kind == ResponseKind.OK && response.data){
        getChildren()
        showNotification({
            message: 'Child created successfully',
            color: "green",
          });

        return true
    }else if(response.kind == 'rejected'){
        sendFailureNotification(NotificationTypes.childCreation, response.errors)

        return false
    }
}   

const deleteChild = async (username: string) => {
    const response = await parentService.deleteChild(username)

    if(response.kind == ResponseKind.OK && response.data){
        getChildren()
        showNotification({
            message: 'Child deleted successfully',
            color: "green",
          });
    }else{
        showNotification({
            message: 'Something went wrong',
            color: "red",
          });
    }
} 

const flagMessage = async (flagMessage: FlaggedData) => {
    const response = await parentService.flagMessage(flagMessage)    
    if(response.kind == ResponseKind.OK){
        sendSuccessNotification(NotificationTypes.flagMessage, "Your complaint was registered, you will hear shortly from our team!")
    }else{
        sendFailureNotification(NotificationTypes.flagMessage, "Your complaint wasn't registered, please try again later!")
    }
}

const getChildSessions = async (username: string) => {

    const response = await parentService.getChildSessions(username)

    if(response.kind == ResponseKind.OK && response.data){
        let sessions = response.data.chat_sessions

        setChildSessions(sessions)

    }else{
        showNotification({
            message: 'Something went wrong',
            color: "red",
          });
    }
}

const onSelectSession = (id: string) => {
    setSelectedSession(id)
}

const getChildMessages = async (username: string, sessionId: string) => {

    const response = await parentService.getChildChat(username, sessionId)

    if(response.kind == ResponseKind.OK && response.data){
        let messages = response.data.messages

        const tempMessages: ChatMessage[] = [{content: "Hi! I am Tutorbot", role: "ai", created_at: ""}]

        setChildMessages([...tempMessages, ...messages])

    }else{
        showNotification({
            message: 'Something went wrong',
            color: "red",
          });
    }
}

return{
    children,
    createChild,
    selectedSession,
    deleteChild,
    getChildMessages,
    onSelectSession,
    childSessions,
    childMessages,
    selectedChild,
    setSelectedChild,
    flagMessage
}
}

export default useChildrenData;