import {
  Burger,
  Group,
  Header,
  MediaQuery,
  Text,
  createStyles,
  useMantineTheme,
  useMantineColorScheme,
} from "@mantine/core";

import { FunctionComponent, useCallback, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { UserRole } from "src/enums/User";
import { useAuth } from "src/hooks/useAuth";
import { authRoutes } from "src/navigators/routes/auth";
import {
  clearChatState,
  clearMessages,
  resetUser,
  updateChatLoading,
} from "src/redux/redux-store";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { ResponseKind } from "src/services/services/MainService";
import { authService } from "src/services/services/auth/auth.api";
import { chatService } from "src/services/services/chat/chat.api";
import { clearToken } from "src/utils/storage";
import { StyledContainer } from "./common/StyledComponents/Container";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import {
  NavBarContainer,
  NavBarParentContainer,
} from "./common/StyledComponents/NavBarContainer";
import { confirmDeletionModal } from "./Modals/ConfirmationModal";
import { IconButton } from "./common/Buttons/IconButton";
import { UserSettingsModal } from "./UserSettingsModal";
import Icon from "./common/Icons/Icons/Icon";
import useResponsive from "src/hooks/useResponsive";
import { FocusButton } from "./common/Buttons/FocusButton";
import { sendFailureNotification } from "src/utils/notifications";
import { NotificationTypes } from "src/enums/Notifications";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/outline";

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : COLORS.bg,

    // Or directly specifying a breakpoint
    "@media (min-width: 992px)": {
      border: 0,
      // Adjustments for devices wider than 768px
    },
  },
}));

enum ButtonKeys {
  dashboard = 0,
  clearChat = 1,
  darkMode = 2,
  deleteAccount = 3,
}
interface TopNavBarProps {
  opened: boolean;
  handleOpen?: () => void;
}

const TopNavbar = (props: TopNavBarProps) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { opened, handleOpen } = props;
  const streaming = useAppSelector((state) => state.chat.streaming);
  const user = useAppSelector((state) => state.user);
  const theme = useMantineTheme();

  const { toggleColorScheme } = useMantineColorScheme();
  const { isMobile, backgroundColor, backgroundColorExterior } =
    useResponsive();

  const deleteAccount = async () => {
      try {
        const response = await authService.deleteAccount();
        if (response.kind == ResponseKind.OK) {
          if (response) {
            logOut()
          }
        } else {
          throw "Error";
        }
      } catch (e) {
      } finally {
      }
    };

  const clearChat = async () => {
    try {
      dispatch(updateChatLoading(true));
      const response = await chatService.clearChat();
      if (response.kind == ResponseKind.OK) {
        if (response) {
          dispatch(clearMessages());
        }
      } else {
        throw "Error";
      }
    } catch (e) {
    } finally {
      dispatch(updateChatLoading(false));
    }
  };

  const handleClick = useCallback(
    (key: ButtonKeys) => {
      switch (key) {
        case ButtonKeys.dashboard:
          return;
        case ButtonKeys.deleteAccount:
          if(user.role == UserRole.admin){
            sendFailureNotification(NotificationTypes.deleteRestricted, "Admin account can not be deleted")
          }
          confirmDeletionModal({
            title: "Delete Account",
            feedback: "This action will delete all your data permenantly",
            onClose: () => {},
            confirmLabel: "Confirm Delete",
            onConfirm: deleteAccount,
          });
          return;
        case ButtonKeys.clearChat:
          if (!streaming) {
            confirmDeletionModal({
              title: "Clear chat",
              feedback: "You are about to clear all chat history",
              onClose: () => {},
              confirmLabel: "Confirm Delete",
              onConfirm: clearChat,
            });
          }
          return;
        case ButtonKeys.darkMode:
          toggleColorScheme();
          return;
        default:
          return;
      }
    },
    [streaming, clearChat]
  );

  const logOut = async () => {
    const response = await authService.signOut();

    if (response.kind == ResponseKind.OK) {
      navigate(authRoutes.LOGIN, { replace: true });
      clearToken();
      dispatch(resetUser());
      dispatch(clearChatState());
      logout();
    }
  };

  return isMobile ? (
    <Header className={classes.header} height={"40"}>
      <NavBarParentContainer
        theme={{ backgroundColor: backgroundColorExterior }}
      >
        <NavBarContainer theme={{ backgroundColor: backgroundColor }}>
          <Group w={"100%"} position="apart">
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
              <Burger
                color={COLORS.icon}
                size={"1rem"}
                opened={opened}
                onClick={handleOpen}
              ></Burger>
            </MediaQuery>
            <div >
              <Text fw={600} size={"1.5rem"}>
                Tutor Bot
              </Text>
              </div>
            <div></div>
            {/* <UserSettingsModal
              deleteAccount={() => handleClick(ButtonKeys.deleteAccount)} 
              userName={user}
              switchToDarkTheme={() => handleClick(ButtonKeys.darkMode)}
              clearChat={() => handleClick(ButtonKeys.clearChat)}
              logOut={logOut}
            /> */}
          </Group>
        </NavBarContainer>
      </NavBarParentContainer>
    </Header>
  ) : (
    <Header className={classes.header} height={"70"}>
      <NavBarParentContainer
        theme={{ backgroundColor: backgroundColorExterior }}
      >
        <NavBarContainer theme={{ backgroundColor: backgroundColor }}>
        <Group position="apart" w={"100%"}>
              <div className="w-52 mx-2">
              <div className="flex flex-row items-center">
              <ChatBubbleOvalLeftIcon className="mr-2 size-7" />
                <span className="text-lg font-semibold truncate">New Chat</span>
              </div>
              </div>
              {/* <Image
               onClick={goToUnwildered}
                width={"12rem"}
                src={"/unwildered-logo-horizontalremovebgpreview-1@2x.png"}
              /> */}
              {/* <UserSettingsModal
                deleteAccount={() => handleClick(ButtonKeys.deleteAccount)} 
                userName={user}
                switchToDarkTheme={() => handleClick(ButtonKeys.darkMode)}
                clearChat={() => handleClick(ButtonKeys.clearChat)}
                logOut={logOut}
              /> */}

              {/* <div className="w-30">
              <FocusButton text="Sign Out" onClick={logOut} icon="logout" />
              </div> */}
            </Group>
        </NavBarContainer>
      </NavBarParentContainer>
    </Header>
  );
};

export default TopNavbar;
