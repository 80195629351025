/**
 * These are configuration settings for the production environment.
 *
 * Do not include API secrets in this file or anywhere in your JS.
 *
 * https://reactnative.dev/docs/security#storing-sensitive-info
 */
export default {
  API_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/",
  AUTH_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/auth",
  USER_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/users",
  PARENT_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/users/parent",
  CHAT_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/users/chat",
  DOCUMENTS_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/users/documents",
  ADMIN_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/admin",
  PAYMENTS_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/payment",
  STRIPE_URL:
    "pk_test_51NahCaLZXSqXiVXjozTuuFZokQQpPh6P8OrXpm8bfNwN2CCy65ZzEhbancBlIOO959raMLxJQvTWnpx9uBb259Xx00mdvwiOuN",
  NOTIFICATION_URL: "https://staging.notifications.app.rentuhbin.com/api/v1",
  PLACES_API_KEY: "AIzaSyD3tOISFMAKvGvm5h_VpUYnpAtC32MM5FU",
  GOOGLE_CLIENT_ID: "520815551653-1b9abo3d4m3iudalgfn15ggv15o8njoh.apps.googleusercontent.com",
  TRANSLATION_API_KEY: "AIzaSyB3BGBVFTDCb8v24JPCiO3BBK0T3dfrMcM",
  TOOLS_ENABLED: false
}
