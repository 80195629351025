import React from 'react';
import useResponsive from "src/hooks/useResponsive";

interface AuthLayoutProps {
  visualComponent: React.ReactNode;
  formComponent: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ visualComponent, formComponent }) => {
  const { isDesktop } = useResponsive();

  return (
    <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col'} h-screen`}>
      <div className={`w-full ${!isDesktop ? 'p-4 hidden' : 'lg:w-1/2 p-8'} flex flex-col justify-center items-center`}>
        {visualComponent}
      </div>
      <div className={`w-full ${!isDesktop ? 'p-4 h-full' : 'lg:w-1/2 p-8 rounded-2xl shadow-lg'} flex flex-col justify-center items-center`}>
        {formComponent}
      </div>
    </div>
  );
};