import { ActionIcon, Text } from "@mantine/core";
import Icon from "src/components/common/Icons/Icons/Icon";

interface FileProps {
    file: File;
    onRemove: () => void;
  }
  
export const FileTile: React.FC<FileProps> = ({ file, onRemove }) => {
    const isImage = file.type.startsWith('image/');
  
    return (
      <div className="flex items-center p-2 rounded-md bg-gray-100 mb-2">
        <Icon name={isImage ? 'image' : 'doc'} size="1.2rem" className="mr-2" />
        <Text size="sm" className="flex-grow mr-2">{file.name}</Text>
        <ActionIcon size="sm" onClick={onRemove}>
          <Icon name="cross" size="0.8rem" />
        </ActionIcon>
      </div>
    );
  };